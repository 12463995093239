import React, { useMemo } from 'react';
import { MessageContainer, MessageText, MessageTitle } from './styles';

var Message = function Message(_ref) {
  var questionMessage = _ref.questionMessage,
      ageId = _ref.ageId;
  var isCorrect = !!questionMessage.correctAnswerText;
  var incorrectTitle = useMemo(function () {
    return ageId === 6 ? 'Обрати внимание!' : 'Обратите внимание!';
  }, [ageId]);
  var text = isCorrect ? questionMessage.correctAnswerText : questionMessage.incorrectAnswerText;
  return React.createElement(MessageContainer, null, React.createElement(MessageTitle, {
    correct: isCorrect
  }, isCorrect && React.createElement("span", null, "\u041F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E!"), !isCorrect && React.createElement("span", null, incorrectTitle)), React.createElement(MessageText, {
    dangerouslySetInnerHTML: {
      __html: text
    }
  }));
};

export default Message;