import styled from 'styled-components';
import { Wrapper } from 'styles/utilities/helpers';
import { fontBase } from 'styles/utilities/typography';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "bv5mwj-0"
})(["", ";padding-top:84px;top:0;min-height:calc(100vh - 115px);transform:none;"], Wrapper());
export var LeftSide = styled.div.withConfig({
  displayName: "styles__LeftSide",
  componentId: "bv5mwj-1"
})(["width:70%;position:absolute;overflow:hidden;"]);
export var RightSide = styled.div.withConfig({
  displayName: "styles__RightSide",
  componentId: "bv5mwj-2"
})(["display:flex;flex-direction:column;position:relative;width:50%;height:calc(100vh - 90px);margin-left:50%;padding-bottom:20px;overflow:hidden;"]);
export var OrganImage = styled.div.withConfig({
  displayName: "styles__OrganImage",
  componentId: "bv5mwj-3"
})(["background-image:url(", ");background-repeat:no-repeat;background-size:cover;background-position:right top;margin:-74px auto 0;min-height:calc(100vh - 10px);"], function (_ref) {
  var src = _ref.src;
  return src;
});
export var DiseaseList = styled.ul.withConfig({
  displayName: "styles__DiseaseList",
  componentId: "bv5mwj-4"
})(["list-style:none;margin:30px 60px 110px 30px;overflow-y:auto;"]);
export var DiseaseListItem = styled.li.withConfig({
  displayName: "styles__DiseaseListItem",
  componentId: "bv5mwj-5"
})(["border-bottom:1px solid ", ";&:first-child{border-top:1px solid ", ";}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.darkIndigo600;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.darkIndigo600;
});
export var DiseaseLink = styled.button.withConfig({
  displayName: "styles__DiseaseLink",
  componentId: "bv5mwj-6"
})(["", ";font-size:2rem;color:", ";text-align:left;cursor:pointer;border:none;background:none;padding:30px 0 30px 15px;width:100%;&:hover,&:active{color:", ";}"], fontBase(), function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.blue;
});
export var BackButtonWrapper = styled.div.withConfig({
  displayName: "styles__BackButtonWrapper",
  componentId: "bv5mwj-7"
})(["position:fixed;display:flex;left:75px;bottom:75px;z-index:10;button{margin-right:30px;min-width:270px;text-align:center;justify-content:center;}"]);
export var Description = styled.div.withConfig({
  displayName: "styles__Description",
  componentId: "bv5mwj-8"
})(["position:absolute;left:0;bottom:75px;padding-left:45px;font-size:1.5rem;color:", ";"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.white;
});