import React from 'react';
import { Helmet } from 'react-helmet';
import GenderSelection from 'components/gender-selection';
import { MainRoute } from 'src/utils/const';

var Gender = function Gender(props) {
  var history = props.history,
      _props$match$params = props.match.params,
      params = _props$match$params === void 0 ? {} : _props$match$params;
  var ageId = Number(params.ageId);

  if (!ageId) {
    history.push(MainRoute.rawUrl());
  }

  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0412\u044B\u0431\u043E\u0440 \u043F\u043E\u043B\u0430")), React.createElement(GenderSelection, {
    history: history,
    ageId: Number(ageId)
  }));
};

export default Gender;