function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PreviewSections, getInitialPreviewBlocks } from 'components/preview-sections';
import { useChildrenGrowing } from 'hooks/children-growing';
import AppContext from 'src/services/context.service';
import { MainRoute, GenderMenuRoute, ChildrenGrowingLandingRoute, IlnessesListRoute } from 'src/utils/const';
import { BackButton } from 'components/button';
import { ScrollBar } from 'components/scrollbar';
import { BackButtonWrapper } from './styles';
export default function ChildrenGrowingLanding(props) {
  var history = props.history,
      params = props.match.params;
  var genderId = Number(params.genderId);
  var ageId = Number(params.ageId);
  var bodyPartId = Number(params.bodyPartId);

  var _useContext = useContext(AppContext),
      setState = _useContext.setState,
      appContext = _useContext.appContext,
      bodyState = _useContext.bodyState;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      previewBlock = _useState2[0],
      setPreviewBlock = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      showEmailResult = _useState4[0],
      setShowEmailResult = _useState4[1];

  var _useChildrenGrowing = useChildrenGrowing(),
      childrenGrowingList = _useChildrenGrowing.childrenGrowingList,
      getChildrenGrowingList = _useChildrenGrowing.getChildrenGrowingList,
      isFetching = _useChildrenGrowing.isFetching;

  var handleGetChildrenGrowingList = function handleGetChildrenGrowingList(bodyId) {
    if (!isFetching) {
      getChildrenGrowingList({
        bodyId: bodyId,
        genderId: genderId
      });
    }
  };

  var handleBackClick = function handleBackClick() {
    history.goBack();
  };

  var handleGenderMenuOpen = function handleGenderMenuOpen() {
    history.push(GenderMenuRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var handleGoToIllness = function handleGoToIllness() {
    history.push(IlnessesListRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      bodyPartId: bodyPartId
    }));
  };

  var handleHomePageOpen = function handleHomePageOpen() {
    history.push(MainRoute.rawUrl());
  };

  var getPageTitle = useCallback(function () {
    var result;

    if (childrenGrowingList.length > 0 && childrenGrowingList[0].name) {
      result = childrenGrowingList[0].name;
    } else {
      var bodyPart = bodyState.bodyParts.find(function (body) {
        return body.id === bodyPartId;
      }) || {
        caption: ''
      };
      result = "\u041E\u0441\u043E\u0431\u0435\u043D\u043D\u043E\u0441\u0442\u044C \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u044F - ".concat(bodyPart.caption);
    }

    return result;
  }, [bodyPartId, bodyState.bodyParts, childrenGrowingList]);
  useEffect(function () {
    if (ageId && genderId) {
      if (appContext.ageId !== ageId && appContext.genderId !== genderId) {
        setState({
          ageId: ageId,
          genderId: genderId
        });
      }
    }
  }, [ageId, appContext.ageId, appContext.genderId, genderId, setState]);
  useEffect(function () {
    if (childrenGrowingList && childrenGrowingList.length > 0) {
      setPreviewBlock(getInitialPreviewBlocks({
        value: childrenGrowingList[0].items
      }));
    }
  }, [childrenGrowingList, childrenGrowingList.length]);
  useEffect(function () {
    if (bodyPartId) {
      handleGetChildrenGrowingList(bodyPartId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, getPageTitle())), React.createElement(ScrollBar, {
    landingId: bodyPartId
  }, React.createElement(PreviewSections, {
    noShift: true,
    showLearnMoreButton: true,
    blocks: previewBlock,
    handleGenderMenuOpen: handleGenderMenuOpen,
    handleHomePageOpen: handleHomePageOpen,
    handleGoToIllness: handleGoToIllness,
    showEmailResult: showEmailResult,
    setShowEmailResult: setShowEmailResult,
    currentLandingUrl: ChildrenGrowingLandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      bodyPartId: bodyPartId
    }),
    setState: setState
  })), !showEmailResult && React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434")));
}