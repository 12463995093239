import styled, { css } from 'styled-components';
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-11awxjy-0"
})(["font-size:24px;line-height:34px;padding:95px 40px;"]);
export var ButtonContainer = styled.div.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-11awxjy-1"
})(["display:flex;align-items:center;justify-content:flex-end;button{width:143px;height:58px;font-size:20px;margin:20px 0;}"]);
export var TitleContainer = styled.div.withConfig({
  displayName: "styles__TitleContainer",
  componentId: "sc-11awxjy-2"
})(["display:flex;align-items:baseline;justify-content:center;font-size:25px;padding-bottom:41px;border-bottom:1px solid ", ";"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.blue;
});
export var BackButton = styled.div.withConfig({
  displayName: "styles__BackButton",
  componentId: "sc-11awxjy-3"
})(["display:flex;align-items:center;justify-content:center;width:143px;height:58px;font-size:20px;margin:20px 0;border:1px solid ", ";border-radius:8px;color:", ";transition:0.3s ease-in-out color;cursor:pointer;&:hover{color:", ";}svg{width:12px;height:12px;fill:", ";margin-right:7px;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.gray300;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.gray300;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.gray300;
});
export var BodyMapContainer = styled.div.withConfig({
  displayName: "styles__BodyMapContainer",
  componentId: "sc-11awxjy-4"
})(["position:fixed;width:600px;left:111.8%;bottom:0;pointer-events:none;z-index:-1;"]);
export var ModalContent = styled.div.withConfig({
  displayName: "styles__ModalContent",
  componentId: "sc-11awxjy-5"
})(["border-radius:8px 0px 0px 8px;min-width:615px;position:relative;pointer-events:all;padding:35px 10px 35px 50px;margin-top:303px;margin-left:75px;margin-right:115px;", ";"], function (_ref6) {
  var theme = _ref6.theme;
  return css(["background-color:", ";box-shadow:0px 0px 63px 7px ", ";color:", ";background-color:", ";box-shadow:20px 0px 103px -9px ", ";&:before{content:'';display:block;border-radius:0 0 30px 60px;background-color:transparent;width:50px;height:45px;position:absolute;right:-40px;top:0;box-shadow:inset 21px -10px 0px 0 ", ";}&:after{content:'';display:block;border-radius:0 0 8px 0;background-color:", ";width:42px;height:calc(100% - 35px);position:absolute;right:-40px;bottom:0;}"], theme.colors.opacity_mainDarkBlue, theme.colors.opacity_blue, theme.colors.white, theme.colors.modalBg, theme.colors.opacity_blue, theme.colors.modalBg, theme.colors.modalBg);
});
export var Close = styled.div.withConfig({
  displayName: "styles__Close",
  componentId: "sc-11awxjy-6"
})(["display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:absolute;top:-30px;right:-70px;padding:15px;border-radius:50%;overflow:hidden;cursor:pointer;pointer-events:all;svg{width:30px;height:30px;fill:", ";}"], function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.white;
});