import React from 'react';
import { StyledBasicButtonComponent, Text, Image, LinkWrapper } from './styles';
import { BasicButton } from '../basic-button';
var StyledBasicButton = StyledBasicButtonComponent(BasicButton);
export var IconButton = function IconButton(_ref) {
  var id = _ref.id,
      iconLink = _ref.iconLink,
      children = _ref.children,
      onClick = _ref.onClick;
  return React.createElement(StyledBasicButton, {
    id: id,
    onClick: onClick
  }, iconLink && React.createElement(LinkWrapper, null, React.createElement(Image, {
    src: iconLink
  })), React.createElement(Text, {
    isChildren: children
  }, children));
};