function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState } from 'react';
import { Environment } from 'src/services';
import { StyledCursor, CursorImg } from './styles';
var redDot = "".concat(Environment.BaseImageURL, "/interesting/dot-red.png");
export function Cursor(props) {
  var posX = props.posX,
      posY = props.posY,
      onClick = props.onClick,
      part = props.part;

  var _useState = useState(redDot),
      _useState2 = _slicedToArray(_useState, 2),
      cursorUrl = _useState2[0],
      setCursorUrl = _useState2[1];

  var handleMouseEnter = function handleMouseEnter() {
    setCursorUrl(redDot);
  };

  var handleMouseLeave = function handleMouseLeave() {
    setCursorUrl(redDot);
  };

  var handleClick = function handleClick(ev) {
    onClick && onClick(part.id, ev);
  };

  var getPosition = function getPosition() {
    return {
      top: "".concat(posY, "px"),
      left: "".concat(posX, "px")
    };
  };

  var getTooltip = function getTooltip() {
    return part && part.caption ? part.caption : '';
  };

  return React.createElement(StyledCursor, {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onClick: handleClick,
    style: getPosition(),
    role: "button",
    title: getTooltip(),
    tabIndex: -1
  }, React.createElement(CursorImg, {
    src: cursorUrl,
    alt: ""
  }));
}