function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useRef, useEffect, useState } from 'react';
import RSC from 'src/vendor/react-scrollbars-custom/rsc';
import chevronUp from 'src/assets/icons/up-chevron.svg';
import chevronDown from 'src/assets/icons/down-chevron.svg';
import { ContentWrapper, TopArrow, BottomArrow } from './styles';
import { scrollTo } from './scroll-to';
export function ScrollBar(_ref) {
  var withTopMenu = _ref.withTopMenu,
      withBottomShift = _ref.withBottomShift,
      _ref$noScrollTop = _ref.noScrollTop,
      noScrollTop = _ref$noScrollTop === void 0 ? false : _ref$noScrollTop,
      children = _ref.children,
      landingId = _ref.landingId;
  var scrollRef = useRef(null);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hasScroll = _useState2[0],
      setHasScroll = _useState2[1];

  var makeScrollTo = function makeScrollTo(scrollShift) {
    scrollTo(scrollRef.current, scrollRef.current.scrollValues.scrollTop + scrollShift, 300);
  };

  var scrollTop = function scrollTop() {
    makeScrollTo(-100);
  };

  var scrollBottom = function scrollBottom() {
    makeScrollTo(100);
  };

  var handleScrollUpdate = function handleScrollUpdate() {
    var _scrollRef$current$ge = scrollRef.current.getScrollState(),
        scrollYPossible = _scrollRef$current$ge.scrollYPossible;

    setHasScroll(scrollYPossible);
  };

  useEffect(function () {
    if (!noScrollTop) {
      scrollRef.current.scrollTop = 0;
    }
  }, [landingId, noScrollTop]);

  var thumbYProps = function thumbYProps(props) {
    var elementRef = props.elementRef,
        restProps = _objectWithoutProperties(props, ["elementRef"]);

    return React.createElement("div", _extends({}, restProps, {
      ref: elementRef,
      style: _objectSpread({}, restProps.style, {
        width: 5,
        backgroundColor: '#005A8F'
      })
    }));
  };

  var trackYProps = function trackYProps(props) {
    var elementRef = props.elementRef,
        restProps = _objectWithoutProperties(props, ["elementRef"]);

    return React.createElement("div", _extends({}, restProps, {
      ref: elementRef,
      style: _objectSpread({}, restProps.style, {
        top: withTopMenu ? 132 : 72,
        right: 40,
        // calc(top position - button position - button image height - indent)
        height: "calc(100% - ".concat(withTopMenu ? 105 : 72, "px - 36px - 17px - 24px - ").concat(withBottomShift ? 36 : 0, "px)"),
        width: 5,
        backgroundColor: '#0b224a'
      })
    }));
  };

  return React.createElement(React.Fragment, null, hasScroll && React.createElement(TopArrow, {
    onClick: scrollTop,
    withTopMenu: withTopMenu
  }, React.createElement("img", {
    src: chevronUp,
    width: "30",
    height: "17",
    alt: "top arrow"
  })), React.createElement(RSC, {
    ref: scrollRef,
    style: {
      width: '100%',
      height: '100vh'
    },
    native: false,
    onUpdate: handleScrollUpdate,
    thumbYProps: {
      renderer: thumbYProps
    },
    trackYProps: {
      renderer: trackYProps
    }
  }, React.createElement(ContentWrapper, {
    hasScroll: hasScroll
  }, children)), hasScroll && React.createElement(BottomArrow, {
    onClick: scrollBottom,
    withBottomShift: withBottomShift
  }, React.createElement("img", {
    src: chevronDown,
    width: "30",
    height: "17",
    alt: "bottom arrow"
  })));
}