import styled from 'styled-components';
export var StyledBasicButtonComponent = function StyledBasicButtonComponent(Component) {
  return styled(Component).withConfig({
    displayName: "styles",
    componentId: "ggpmlw-0"
  })(["display:flex;align-items:center;text-align:left;padding:0 30px;height:85px;"]);
};
export var LinkWrapper = styled.div.withConfig({
  displayName: "styles__LinkWrapper",
  componentId: "ggpmlw-1"
})(["display:flex;align-items:center;justify-content:center;width:35px;height:35px;"]);
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "ggpmlw-2"
})(["font-size:1.5rem;margin-left:", ";"], function (_ref) {
  var isChildren = _ref.isChildren;
  return isChildren ? '30px' : null;
});
export var Image = styled.div.withConfig({
  displayName: "styles__Image",
  componentId: "ggpmlw-3"
})(["background-image:url(", ");background-repeat:no-repeat;background-position:center;background-size:contain;width:100%;height:100%;"], function (_ref2) {
  var src = _ref2.src;
  return src;
});