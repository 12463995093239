function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from 'src/services';
import { ZogCenterLandingRoute } from 'src/utils/const';
import useModal from 'hooks/use-modal';
import HintButton from 'components/hint-button';
import { BasicModal } from 'components/modal';
import { BasicButton } from 'components/button';
import { ArrowBack } from 'components/svg-icons';
import { Wrapper, Text, ButtonContainer, HintContainer, CenterBlock, DatePickerIcon, CenterBlockTitle, TitleContainer, SkipButton, BackButton, BacklitPetal } from './styles';

var ZogHint = function ZogHint(props) {
  var history = props.history,
      ageId = props.ageId,
      genderId = props.genderId;
  var timer = useRef(null);

  var _useModal = useModal(),
      _useModal2 = _slicedToArray(_useModal, 2),
      isShowingModal = _useModal2[0],
      toggleModal = _useModal2[1];

  var _useState = useState(true),
      _useState2 = _slicedToArray(_useState, 2),
      isShowingFirstHint = _useState2[0],
      setIsFirstHint = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isShowingSecondHint = _useState4[0],
      setIsSecondHint = _useState4[1];

  var _useContext = useContext(AppContext),
      hintShowing = _useContext.hintShowing,
      setIsHintShowing = _useContext.setIsHintShowing;

  var _useState5 = useState(true),
      _useState6 = _slicedToArray(_useState5, 2),
      shouldRestartimer = _useState6[0],
      setShouldRestartTimer = _useState6[1];

  var setHintInterval = function setHintInterval(tick, remainedSeconds) {
    var remained = remainedSeconds;
    timer.current = setInterval(function () {
      if (remained === 0) {
        customToggleModal();
        clearInterval(timer.current);
      } else {
        remained -= 1;
      }
    }, tick);
  };

  var customToggleModal = function customToggleModal() {
    toggleModal(false);
    clearInterval(timer.current);
    setIsHintShowing('zog', false);
  };

  var toggleFirstHint = function toggleFirstHint() {
    setIsFirstHint(false);
    setIsSecondHint(true);
    clearInterval(timer.current);

    if (shouldRestartimer) {
      setHintInterval(1000, 5);
    }
  };

  var toggleSecondHint = function toggleSecondHint() {
    setIsFirstHint(true);
    setIsSecondHint(false);
  };

  var toggleHintModal = function toggleHintModal() {
    setIsFirstHint(true);
    setIsSecondHint(false);
    setShouldRestartTimer(false);
    toggleModal();
  };

  var handleZogCenterLandingOpen = function handleZogCenterLandingOpen() {
    customToggleModal();
    history.push(ZogCenterLandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var renderZogSelectionHintModal = function renderZogSelectionHintModal() {
    return React.createElement(BasicModal, {
      title: "",
      isOpen: isShowingModal || hintShowing.zog,
      onClose: customToggleModal,
      showClose: true,
      modalHeight: 488,
      isLeft: true
    }, isShowingFirstHint && React.createElement(Wrapper, null, React.createElement(TitleContainer, null, "\u041F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0438"), React.createElement(Text, null, "\u041D\u0430\u0436\u043C\u0438 \u043D\u0430 \u043B\u044E\u0431\u043E\u0439 \u0441\u0435\u043A\u0442\u043E\u0440 ", React.createElement("br", null), " \u0438 \u043F\u0435\u0440\u0435\u0439\u0434\u0438 \u0432 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u0443\u044E\u0449\u0438\u0439 ", React.createElement("br", null), " \u0440\u0430\u0437\u0434\u0435\u043B"), React.createElement(BacklitPetal, null), React.createElement(ButtonContainer, null, React.createElement(SkipButton, {
      onClick: customToggleModal
    }, "\u041F\u0440\u043E\u043F\u0443\u0441\u0442\u0438\u0442\u044C"), React.createElement(BasicButton, {
      onClick: toggleFirstHint
    }, "\u0414\u0430\u043B\u0435\u0435"))), isShowingSecondHint && React.createElement(Wrapper, null, React.createElement(TitleContainer, null, "\u041F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0438"), React.createElement(HintContainer, null, React.createElement(CenterBlock, {
      onClick: handleZogCenterLandingOpen
    }, React.createElement(DatePickerIcon, null), React.createElement(CenterBlockTitle, null, "\u042F \u0432\u044B\u0431\u0438\u0440\u0430\u044E \u0437\u0434\u043E\u0440\u043E\u0432\u044C\u0435!"))), React.createElement(Text, null, "\u041D\u0430\u0436\u043C\u0438 \u043D\u0430 \u0446\u0435\u043D\u0442\u0440 \u043A\u0440\u0443\u0433\u0430 \u0438 \u043F\u043E\u043B\u0443\u0447\u0438 ", React.createElement("br", null), " \u0440\u0435\u043A\u043E\u043C\u0435\u043D\u0434\u0430\u0446\u0438\u0438 \u043F\u043E \u0437\u0434\u043E\u0440\u043E\u0432\u043E\u043C\u0443 \u043E\u0431\u0440\u0430\u0437\u0443 \u0436\u0438\u0437\u043D\u0438"), React.createElement(ButtonContainer, null, React.createElement(BackButton, {
      onClick: toggleSecondHint
    }, React.createElement(ArrowBack, null), " \u041D\u0430\u0437\u0430\u0434"), React.createElement(BasicButton, {
      onClick: customToggleModal
    }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"))));
  };

  useEffect(function () {
    setHintInterval(1000, 5); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.createElement(React.Fragment, null, renderZogSelectionHintModal(), React.createElement(HintButton, {
    handleClick: toggleHintModal
  }));
};

export default ZogHint;