export var ExtraMenuTypes;

(function (ExtraMenuTypes) {
  ExtraMenuTypes["BODY_PARTS"] = "body-parts";
  ExtraMenuTypes["EXTRA_MENU"] = "extra-menu";
  ExtraMenuTypes["ZOG"] = "zog";
  ExtraMenuTypes["ALL_SICKNESS"] = "all-sickness";
  ExtraMenuTypes["LANDINGS"] = "landings";
  ExtraMenuTypes["TEST"] = "test";
  ExtraMenuTypes["CHILDREN_GROWING"] = "children-growing";
})(ExtraMenuTypes || (ExtraMenuTypes = {}));