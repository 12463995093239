import * as React from 'react';
import styled from 'styled-components';
var StyledLine = styled.svg.withConfig({
  displayName: "line__StyledLine",
  componentId: "nqdopa-0"
})(["position:absolute;left:0;right:0;top:0;bottom:0;width:100%;height:100%;z-index:2;pointer-events:none;opacity:", ";line{stroke:", ";transition:opacity 0.1s ease-in-out;}"], function (_ref) {
  var x2 = _ref.x2;
  return x2 > 0 ? '1' : '0';
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.blue;
});

var Line = function Line(_ref3) {
  var _ref3$x = _ref3.x1,
      x1 = _ref3$x === void 0 ? 0 : _ref3$x,
      _ref3$x2 = _ref3.x2,
      x2 = _ref3$x2 === void 0 ? 0 : _ref3$x2,
      _ref3$y = _ref3.y1,
      y1 = _ref3$y === void 0 ? 0 : _ref3$y,
      _ref3$y2 = _ref3.y2,
      y2 = _ref3$y2 === void 0 ? 0 : _ref3$y2;
  return React.createElement(StyledLine, {
    x2: x2
  }, React.createElement("line", {
    x1: x1,
    x2: x2,
    y1: y1,
    y2: y2
  }));
};

export default Line;