function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useContext, useCallback } from 'react';
import { AppContext } from 'src/services';
import { BasicModal } from '../modal';
import HintButton from '../hint-button';
import useModal from '../../hooks/use-modal';
import { ModalWrapper, Text, ArrowContainer, Arrow } from './styles';
import ArrowIconSrc from 'src/assets/icons/hint-arrow.png';

var Hint = function Hint() {
  var _useModal = useModal(),
      _useModal2 = _slicedToArray(_useModal, 2),
      isShowingHintModal = _useModal2[0],
      toggleHintModal = _useModal2[1];

  var _useContext = useContext(AppContext),
      hintShowing = _useContext.hintShowing,
      setIsHintShowing = _useContext.setIsHintShowing;

  var toggle = useCallback(function () {
    var isOpenModal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : !isShowingHintModal;
    toggleHintModal(isOpenModal);
  }, [isShowingHintModal, toggleHintModal]);

  var renderHintModal = function renderHintModal() {
    return React.createElement(BasicModal, {
      title: "",
      isOpen: isShowingHintModal || hintShowing.hint,
      onClose: customToggleModal,
      isFullWidth: true,
      clickClose: true
    }, React.createElement(ModalWrapper, null, React.createElement(Text, null, "\u041B\u0438\u0441\u0442\u0430\u0439\u0442\u0435 \u0432\u0432\u0435\u0440\u0445"), React.createElement(ArrowContainer, null, React.createElement(Arrow, {
      src: ArrowIconSrc,
      alt: "icon"
    }))));
  };

  var customToggleModal = function customToggleModal() {
    toggle(false);
    setIsHintShowing('hint', false);
  };

  var toggleModal = function toggleModal() {
    toggle();
  };

  useEffect(function () {
    var remained = 5;
    var timer = setInterval(function () {
      if (remained === 0) {
        customToggleModal();
        clearInterval(timer);
      } else {
        remained -= 1;
      }
    }, 1000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.createElement(React.Fragment, null, renderHintModal(), React.createElement(HintButton, {
    handleClick: toggleModal
  }));
};

export default Hint;