function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { getImageLink } from 'src/utils';
import { Cursor } from './cursor';
import { BodyMapWrapper, ImageWrapper, Avatar, OrganImage } from './styles';
export default function BodyMap(props) {
  var bodyUrl = props.bodyUrl,
      currentBodyPartId = props.currentBodyPartId,
      _props$dotPosition = props.dotPosition,
      posx = _props$dotPosition.posx,
      posy = _props$dotPosition.posy,
      bodyParts = props.bodyParts,
      onCursorClick = props.onCursorClick,
      _props$showOrganMap = props.showOrganMap,
      showOrganMap = _props$showOrganMap === void 0 ? false : _props$showOrganMap;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      currentBodyPart = _useState2[0],
      setCurrentBodyPart = _useState2[1];

  var showMap = useMemo(function () {
    return showOrganMap && currentBodyPart && currentBodyPart.organMapImage;
  }, [currentBodyPart, showOrganMap]);
  useEffect(function () {
    if (currentBodyPartId) {
      var bodyPart = bodyParts.find(function (item) {
        return item.id === currentBodyPartId;
      });
      setCurrentBodyPart(bodyPart);
    }
  }, [currentBodyPartId, bodyParts, currentBodyPart]);
  return React.createElement(BodyMapWrapper, null, React.createElement(ImageWrapper, null, bodyUrl && React.createElement(Avatar, {
    src: bodyUrl,
    alt: "Avatar"
  }), bodyParts.length > 0 ? React.createElement(React.Fragment, null, showMap && React.createElement(OrganImage, {
    src: getImageLink(currentBodyPart.organMapImage)
  }), bodyParts.map(function (part) {
    return React.createElement(Fragment, {
      key: "frag-".concat(part.id)
    }, !currentBodyPartId && showOrganMap && part.organMapImage && React.createElement(OrganImage, {
      src: getImageLink(part.organMapImage)
    }), part.visibleDot && React.createElement(Cursor, {
      key: part.id,
      posX: part.posx,
      posY: part.posy,
      part: part,
      onClick: onCursorClick
    }));
  })) : React.createElement(Cursor, {
    posX: posx,
    posY: posy
  })));
}