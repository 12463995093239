import styled from 'styled-components';
export var Button = styled.button.withConfig({
  displayName: "styles__Button",
  componentId: "j9mtpu-0"
})(["border:2px solid ", ";background:transparent;padding:10px 30px;font-size:1.5rem;color:", ";transition:box-shadow,0.3s ease-in-out;cursor:pointer;&:hover{box-shadow:0px 0px 10px 0px ", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.opacity_blue;
});