function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { Fragment, useCallback, useEffect } from 'react';
import { AnatomyRoute, IlnessesListRoute, ZogRoute, LandingRoute, GenderRoute, ChildrenGrowingLandingRoute, ChildrenGrowingRoute, TestRoute } from 'src/utils/const';
import { Environment } from 'src/services';
import { getImageLink } from 'src/utils';
import supportLine from 'src/assets/support-line.pdf';
import useModal from 'hooks/use-modal';
import { MenuButton, BackButton } from 'components/button';
import { BasicModal } from 'components/modal';
import GenderImage from 'components/gender-image';
import { genderDataMenu } from 'components/gender-selection/const';
import PeopleSearchIcon from 'src/assets/icons/people_search.png';
import PeopleIcon from 'src/assets/icons/people.png';
import HandsIcon from 'src/assets/icons/hands.png';
import ZozhIcon from 'src/assets/icons/zozh.png';
import ShieldIcon from 'src/assets/icons/shield.png';
import InfoIcon from 'src/assets/icons/info.png';
import SmileIcon from 'src/assets/icons/smile.svg';
import PhoneIcon from 'src/assets/icons/phone.svg';
import SearchHeart from 'src/assets/icons/search-heart.svg';
import { AllDisease } from 'components/svg-icons';
import { Container, Header, Title, SubTitle, Main, RightSide, LeftSide, BackButtonWrapper, ButtonContainer, ModalHiddenContent } from './styles';
var baseImgUrl = Environment.BaseImageURL;

var Menu = function Menu(_ref) {
  var history = _ref.history,
      ageId = _ref.ageId,
      genderId = _ref.genderId,
      bodyParts = _ref.bodyParts,
      ageMenu = _ref.ageMenu,
      extraMenu = _ref.extraMenu,
      setState = _ref.setState;

  var _useModal = useModal(),
      _useModal2 = _slicedToArray(_useModal, 2),
      isShowingImportantSickness = _useModal2[0],
      toggleImportantSickness = _useModal2[1];

  var _useModal3 = useModal(),
      _useModal4 = _slicedToArray(_useModal3, 2),
      isSowingFeaturesEvolution = _useModal4[0],
      toggleFeaturesEvolution = _useModal4[1];

  var _useModal5 = useModal(),
      _useModal6 = _slicedToArray(_useModal5, 2),
      isSowingProphylaxis = _useModal6[0],
      toggleProphylaxis = _useModal6[1];

  var _useModal7 = useModal(),
      _useModal8 = _slicedToArray(_useModal7, 2),
      isSowingInsuredService = _useModal8[0],
      toggleInsuredService = _useModal8[1];

  var _useModal9 = useModal(),
      _useModal10 = _slicedToArray(_useModal9, 2),
      isSowingSupportLine = _useModal10[0],
      toggleSupportLine = _useModal10[1];

  var _ageMenu$filter = ageMenu.filter(function (menuItem) {
    return menuItem.id === ageId;
  }),
      _ageMenu$filter2 = _slicedToArray(_ageMenu$filter, 1),
      currentMenu = _ageMenu$filter2[0];

  var imageGenderType = currentMenu && (genderId === 1 ? currentMenu.mainMenuMale : currentMenu.mainMenuFemale);
  var handleBackClick = useCallback(function () {
    history.push(GenderRoute.rawUrl({
      ageId: ageId
    }));
  }, [ageId, history]);
  var handleBodyOpen = useCallback(function (ev) {
    var bodyPartId = ev.currentTarget.dataset.id || 0;
    history.push(IlnessesListRoute.rawUrl({
      bodyPartId: bodyPartId,
      genderId: genderId,
      ageId: ageId
    }));
  }, [ageId, genderId, history]);
  var handleProtectionRightsOpen = useCallback(function () {
    setState({
      landingType: 'protection-rights-landing'
    });
    var singleItem = extraMenu && extraMenu.filter(function (item) {
      return item.protectionRights;
    }).map(function (item) {
      return item.id;
    }).slice(0, 1);
    history.push(LandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      landingId: singleItem[0]
    }));
  }, [ageId, extraMenu, genderId, history, setState]);
  var handleStaticLandingsOpen = useCallback(function (landingType) {
    return function (ev) {
      var bodyPartId = ev.currentTarget.dataset.id || 0;
      setState({
        landingType: landingType
      });
      history.push(LandingRoute.rawUrl({
        ageId: ageId,
        genderId: genderId,
        landingId: bodyPartId
      }));
    };
  }, [ageId, genderId, history, setState]);
  var handleAllSicknessOpen = useCallback(function () {
    history.push(AnatomyRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  }, [ageId, genderId, history]);
  var handleZogMenuOpen = useCallback(function () {
    setState({
      landingType: 'zog-landing'
    });
    history.push(ZogRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  }, [ageId, genderId, history, setState]);
  var handleChildrenGrowingOpen = useCallback(function (ev) {
    setState({
      landingType: 'children-growing-landing'
    });
    var bodyPartId = ev.currentTarget.dataset.id || 0;
    history.push(ChildrenGrowingLandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      bodyPartId: bodyPartId
    }));
  }, [ageId, genderId, history, setState]);
  var handleAllChildrenGrowingOpen = useCallback(function () {
    history.push(ChildrenGrowingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  }, [ageId, genderId, history]);
  var handleTestOpen = useCallback(function () {
    history.push(TestRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  }, [ageId, genderId, history]);
  var renderImportantSicknessModal = useCallback(function () {
    return React.createElement(BasicModal, {
      title: "\u041D\u0430\u0438\u0431\u043E\u043B\u0435\u0435 \u0437\u043D\u0430\u0447\u0438\u043C\u044B\u0435 \u0437\u0430\u0431\u043E\u043B\u0435\u0432\u0430\u043D\u0438\u044F",
      isOpen: isShowingImportantSickness,
      onClose: toggleImportantSickness,
      isAnimated: true,
      clickClose: true,
      showClose: true,
      modalHeight: 670,
      isFullWidth: true
    }, React.createElement(ButtonContainer, null, React.createElement(Fragment, null, ageId === 7 && React.createElement(MenuButton, {
      bigButton: true,
      key: "body-0",
      iconComponent: React.createElement(AllDisease, null),
      onClick: handleAllSicknessOpen
    }, "\u0412\u0441\u0435 \u0437\u0430\u0431\u043E\u043B\u0435\u0432\u0430\u043D\u0438\u044F"), bodyParts && bodyParts.slice(0, 8).sort(function (a, b) {
      return a.caption.toLowerCase().localeCompare(b.caption.toLowerCase());
    }).map(function (item) {
      return React.createElement(MenuButton, {
        bigButton: true,
        key: "body-".concat(item.id),
        iconLink: getImageLink(item.icon),
        onClick: handleBodyOpen,
        id: item.id
      }, item.diseaseButtonCaption || item.caption);
    }))));
  }, [ageId, bodyParts, handleAllSicknessOpen, handleBodyOpen, isShowingImportantSickness, toggleImportantSickness]);
  var renderFeaturesEvolutionModal = useCallback(function () {
    return React.createElement(BasicModal, {
      title: "\u041E\u0441\u043E\u0431\u0435\u043D\u043D\u043E\u0441\u0442\u0438 \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u044F",
      isOpen: isSowingFeaturesEvolution,
      onClose: toggleFeaturesEvolution,
      isAnimated: true,
      showClose: true,
      clickClose: true,
      modalHeight: 670,
      isFullWidth: true
    }, React.createElement(ButtonContainer, null, ageId < 7 && React.createElement(MenuButton, {
      bigButton: true,
      key: "children-growing-0",
      iconComponent: React.createElement(AllDisease, null),
      onClick: handleAllChildrenGrowingOpen
    }, "\u0412\u0441\u0435 \u043E\u0441\u043E\u0431\u0435\u043D\u043D\u043E\u0441\u0442\u0438 \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u044F"), bodyParts && bodyParts.slice(0, 8).sort(function (a, b) {
      return a.caption.toLowerCase().localeCompare(b.caption.toLowerCase());
    }).map(function (item) {
      return React.createElement(MenuButton, {
        bigButton: true,
        iconLink: getImageLink(item.icon),
        id: item.id,
        key: "children-growing-".concat(item.id),
        onClick: handleChildrenGrowingOpen
      }, item.growthButtonCaption || item.caption);
    })));
  }, [ageId, bodyParts, handleAllChildrenGrowingOpen, handleChildrenGrowingOpen, isSowingFeaturesEvolution, toggleFeaturesEvolution]);
  var renderProphylaxisModal = useCallback(function () {
    return React.createElement(BasicModal, {
      title: "\u0412\u0441\u0451 \u043E \u043F\u0440\u043E\u0444\u0438\u043B\u0430\u043A\u0442\u0438\u043A\u0435",
      isOpen: isSowingProphylaxis,
      onClose: toggleProphylaxis,
      isAnimated: true,
      clickClose: true,
      showClose: true,
      modalHeight: 670,
      isFullWidth: true
    }, React.createElement(ButtonContainer, null, React.createElement(MenuButton, {
      bigButton: true,
      key: "extra-menu-0",
      iconLink: SearchHeart,
      onClick: handleTestOpen
    }, ageId < 7 && React.createElement("span", null, "\u0422\u0435\u0441\u0442: \u0417\u0434\u043E\u0440\u043E\u0432\u044B\u0439 \u0440\u0435\u0431\u0451\u043D\u043E\u043A"), ageId === 7 && React.createElement("span", null, "\u0422\u0435\u0441\u0442: \u041F\u0440\u043E\u0432\u0435\u0440\u044C \u0441\u0432\u043E\u0439 \u043E\u0431\u0440\u0430\u0437 \u0436\u0438\u0437\u043D\u0438")), extraMenu && extraMenu.filter(function (item) {
      return item.showInPrevention && (item.menuId === 0 || item.menuId === ageId);
    }).slice(0, 9).map(function (item) {
      return React.createElement(MenuButton, {
        bigButton: true,
        key: "extra-menu-".concat(item.id),
        id: item.id,
        iconLink: getImageLink(item.icon),
        onClick: handleStaticLandingsOpen('prophylaxis-landing')
      }, item.name);
    })));
  }, [ageId, extraMenu, handleStaticLandingsOpen, handleTestOpen, isSowingProphylaxis, toggleProphylaxis]);
  var renderInsuredServiceModal = useCallback(function () {
    return React.createElement(BasicModal, {
      title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043F\u0440\u043E \u041E\u041C\u0421",
      isOpen: isSowingInsuredService,
      onClose: toggleInsuredService,
      isAnimated: true,
      clickClose: true,
      showClose: true,
      modalHeight: 670,
      isFullWidth: true
    }, React.createElement(ButtonContainer, null, React.createElement(Fragment, null, extraMenu.filter(function (item) {
      return item.showInClientService;
    }).map(function (item) {
      return React.createElement(MenuButton, {
        bigButton: true,
        id: item.id,
        key: "body-".concat(item.id),
        iconLink: getImageLink(item.icon),
        onClick: handleStaticLandingsOpen('oms-landing')
      }, item.name);
    }))));
  }, [extraMenu, handleStaticLandingsOpen, isSowingInsuredService, toggleInsuredService]);
  var renderSupportLineModal = useCallback(function () {
    return React.createElement(BasicModal, {
      title: "\u041B\u0438\u043D\u0438\u044F \u043F\u043E\u043C\u043E\u0449\u0438",
      isOpen: isSowingSupportLine,
      onClose: toggleSupportLine,
      isAnimated: true,
      clickClose: true,
      showClose: true,
      modalHeight: 900,
      isFullWidth: true
    }, React.createElement(ModalHiddenContent, null, React.createElement("embed", {
      src: supportLine,
      width: "100%",
      height: "100%",
      type: "application/pdf"
    })));
  }, [isSowingSupportLine, toggleSupportLine]);
  var handleToggleFeatures = useCallback(function () {
    toggleFeaturesEvolution(true);
  }, [toggleFeaturesEvolution]);
  var handleToggleImportant = useCallback(function () {
    toggleImportantSickness(true);
  }, [toggleImportantSickness]);
  var handleToggleProphylaxis = useCallback(function () {
    toggleProphylaxis(true);
  }, [toggleProphylaxis]);
  var handleToggleInsured = useCallback(function () {
    toggleInsuredService(true);
  }, [toggleInsuredService]);
  var handleOpenChildRights = useCallback(function () {
    setState({
      landingType: 'children-rights-landing'
    });
    var singleItem = extraMenu && extraMenu.filter(function (item) {
      return item.childrenRights;
    }).map(function (item) {
      return item.id;
    }).slice(0, 1);
    history.push(LandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      landingId: singleItem[0]
    }));
  }, [ageId, extraMenu, genderId, history, setState]);
  var handleOpenSupport = useCallback(function () {
    setState({
      landingType: 'support-landing'
    });
    var singleItem = extraMenu && extraMenu.filter(function (item) {
      return item.support;
    }).map(function (item) {
      return item.id;
    }).slice(0, 1);
    history.push(LandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      landingId: singleItem[0]
    }));
  }, [ageId, extraMenu, genderId, history, setState]);
  useEffect(function () {
    if (ageMenu.length > 0 && !currentMenu) {
      handleBackClick();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [ageMenu.length, currentMenu]);
  return React.createElement(Container, null, currentMenu && React.createElement(React.Fragment, null, React.createElement(LeftSide, null, React.createElement(GenderImage, {
    genderId: genderId,
    imgSrc: "".concat(baseImgUrl).concat(imageGenderType),
    title: genderDataMenu[genderId][ageId]
  })), React.createElement(RightSide, null, React.createElement(Header, null, React.createElement(Title, null, "\u0413\u043B\u0430\u0432\u043D\u043E\u0435 \u043C\u0435\u043D\u044E"), React.createElement(SubTitle, null, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0430\u0437\u0434\u0435\u043B")), React.createElement(Main, null, ageId !== 7 && React.createElement(MenuButton, {
    iconLink: PeopleIcon,
    onClick: handleToggleFeatures
  }, "\u041E\u0441\u043E\u0431\u0435\u043D\u043D\u043E\u0441\u0442\u0438 \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u044F"), React.createElement(MenuButton, {
    iconLink: PeopleSearchIcon,
    onClick: handleToggleImportant
  }, "\u041D\u0430\u0438\u0431\u043E\u043B\u0435\u0435 \u0437\u043D\u0430\u0447\u0438\u043C\u044B\u0435 ", React.createElement("br", null), " \u0437\u0430\u0431\u043E\u043B\u0435\u0432\u0430\u043D\u0438\u044F"), React.createElement(MenuButton, {
    iconLink: HandsIcon,
    onClick: handleToggleProphylaxis
  }, "\u0412\u0441\u0451 \u043E \u043F\u0440\u043E\u0444\u0438\u043B\u0430\u043A\u0442\u0438\u043A\u0435"), React.createElement(MenuButton, {
    iconLink: ZozhIcon,
    onClick: handleZogMenuOpen
  }, "\u0420\u0435\u043A\u043E\u043C\u0435\u043D\u0434\u0430\u0446\u0438\u0438 ", React.createElement("br", null), " \u043F\u043E \u0437\u0434\u043E\u0440\u043E\u0432\u043E\u043C\u0443 \u043E\u0431\u0440\u0430\u0437\u0443 \u0436\u0438\u0437\u043D\u0438"), extraMenu.find(function (item) {
    return item.protectionRights;
  }) && React.createElement(MenuButton, {
    iconLink: ShieldIcon,
    onClick: handleProtectionRightsOpen
  }, "\u0417\u0430\u0449\u0438\u0442\u0430 \u043F\u0440\u0430\u0432 \u0437\u0430\u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u043D\u044B\u0445"), React.createElement(MenuButton, {
    iconLink: InfoIcon,
    onClick: handleToggleInsured
  }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043F\u0440\u043E \u041E\u041C\u0421"), extraMenu.find(function (item) {
    return item.support;
  }) && React.createElement(MenuButton, {
    iconLink: PhoneIcon,
    onClick: handleOpenSupport
  }, "\u041B\u0438\u043D\u0438\u044F \u043F\u043E\u043C\u043E\u0449\u0438"), extraMenu.find(function (item) {
    return item.childrenRights;
  }) && React.createElement(MenuButton, {
    iconLink: SmileIcon,
    onClick: handleOpenChildRights
  }, "\u0414\u0435\u0442\u0441\u043A\u043E\u0435 \u043F\u0440\u0430\u0432\u043E")))), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434")), renderImportantSicknessModal(), renderFeaturesEvolutionModal(), renderProphylaxisModal(), renderInsuredServiceModal(), renderSupportLineModal());
};

export default Menu;