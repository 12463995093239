import * as React from 'react';
import { BlockImage } from 'components/svg-icons/styles';
import AllDiseaseImage from './icons/all-disease.png';

var AllDisease = function AllDisease() {
  return React.createElement(BlockImage, {
    src: AllDiseaseImage
  });
};

export default AllDisease;