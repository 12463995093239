function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useCallback, useEffect, useState, useRef } from 'react';
import HintButton from 'components/hint-button';
import BodyMap from 'components/body-map';
import { BasicModal } from 'components/modal';
import { CloseIcon } from 'components/svg-icons';
import { Text, ButtonContainer, TitleContainer, BodyMapContainer, ModalContent, Close } from './styles';
import { BasicButton } from '../button';
var initialDotPosition = {
  posx: 0,
  posy: 0
};

var FeaturesHint = function FeaturesHint(_ref) {
  var bodyParts = _ref.bodyParts,
      isOpen = _ref.isOpen,
      onClose = _ref.onClose,
      text = _ref.text;
  var timer = useRef(null);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isShowing = _useState2[0],
      setIsShowing = _useState2[1];

  var setHintInterval = function setHintInterval(tick, remainedSeconds) {
    var remained = remainedSeconds;
    timer.current = setInterval(function () {
      if (remained === 0) {
        customToggleModal();
        clearInterval(timer.current);
      } else {
        remained -= 1;
      }
    }, tick);
  };

  var toggle = useCallback(function () {
    var isOpenMpdal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : !isShowing;
    setIsShowing(isOpenMpdal);
  }, [isShowing]);
  var customToggleModal = useCallback(function () {
    toggle(false);
    clearInterval(timer.current);
    onClose();
  }, [onClose, toggle]);

  var toggleHintModal = function toggleHintModal() {
    toggle();
  };

  useEffect(function () {
    setHintInterval(1000, 5); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var defaultText = 'Выберите орган или систему <br /> на теле, нажав на любую точку, <br /> чтобы узнать о рисках для здоровья';

  var renderFeaturesSelectionHintModal = function renderFeaturesSelectionHintModal() {
    return React.createElement(BasicModal, {
      title: "",
      isOpen: isOpen || isShowing,
      onClose: customToggleModal,
      showClose: true,
      isLeft: true,
      isFullHeightBlock: true,
      clickClose: true,
      noContentContainer: true
    }, React.createElement(BodyMapContainer, null, React.createElement(BodyMap, {
      dotPosition: initialDotPosition,
      bodyParts: bodyParts,
      showOrganMap: true
    })), React.createElement(ModalContent, null, React.createElement(TitleContainer, null, "\u041F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0430"), React.createElement(Close, {
      role: "button",
      tabIndex: -1,
      onClick: customToggleModal
    }, React.createElement(CloseIcon, null)), React.createElement(Text, {
      dangerouslySetInnerHTML: {
        __html: text || defaultText
      }
    }), React.createElement(ButtonContainer, null, React.createElement(BasicButton, {
      onClick: customToggleModal
    }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"))));
  };

  return React.createElement(React.Fragment, null, renderFeaturesSelectionHintModal(), React.createElement(HintButton, {
    handleClick: toggleHintModal
  }));
};

export default FeaturesHint;