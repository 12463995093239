function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IlnessesListRoute, GenderMenuRoute, MainRoute } from 'src/utils/const';
import AppContext from 'services/context.service';
import { Environment } from 'src/services';
import ExtraMenu from 'components/extra-menu';
import { ExtraMenuTypes } from 'components/extra-menu/interface';
import BodyMap from 'components/body-map';
import { BackButton, IconButton } from 'components/button';
import FeaturesHint from 'components/features-hint';
import OrganHint from 'components/organ-hint';
import HouseIcon from 'src/assets/icons/house.png';
import MenuIcon from 'src/assets/icons/menu.svg';
import { BackButtonWrapper, BodyMapContainer, Container, Description, Title } from './styles';
var baseImgUrl = Environment.BaseImageURL;
var initialDotPosition = {
  posx: 0,
  posy: 0
};

var Anatomy = function Anatomy(props) {
  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      bodyUrl = _useState2[0],
      setBodyUrl = _useState2[1];

  var _props$match$params = props.match.params,
      params = _props$match$params === void 0 ? {
    ageId: 0,
    genderId: 0
  } : _props$match$params,
      history = props.history;
  var ageId = Number(params.ageId);
  var genderId = Number(params.genderId);

  var _useContext = useContext(AppContext),
      ageMenu = _useContext.ageMenu,
      bodyState = _useContext.bodyState,
      setState = _useContext.setState,
      hintShowing = _useContext.hintShowing,
      setIsHintShowing = _useContext.setIsHintShowing,
      extraMenuList = _useContext.extraMenuList,
      getExtraMenuIndex = _useContext.getExtraMenuIndex;

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      currentBodyPartId = _useState4[0],
      setCurrentBodyPartId = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isOpenOrgan = _useState6[0],
      setIsOpenOrgan = _useState6[1];

  var _useState7 = useState({
    pageX: 0,
    pageY: 0
  }),
      _useState8 = _slicedToArray(_useState7, 2),
      activeBodyPartCoordinat = _useState8[0],
      setActiveBodyPartCoordinat = _useState8[1];

  var _useState9 = useState(null),
      _useState10 = _slicedToArray(_useState9, 2),
      initialSlide = _useState10[0],
      setInitialSlide = _useState10[1];

  var _ageMenu$filter = ageMenu.filter(function (ageMenuItem) {
    return ageMenuItem.id === ageId;
  }),
      _ageMenu$filter2 = _slicedToArray(_ageMenu$filter, 1),
      currentAgeMenu = _ageMenu$filter2[0];

  var handleBackClick = function handleBackClick(event) {
    event.preventDefault();
    history.goBack();
  };

  var handleGenderMenuRouteOpen = function handleGenderMenuRouteOpen() {
    history.push(GenderMenuRoute.rawUrl({
      genderId: genderId,
      ageId: ageId
    }));
  };

  var handleOpenMainPage = function handleOpenMainPage() {
    history.push(MainRoute.rawUrl());
  };

  var handleBodyPartClick = function handleBodyPartClick(bodyPartId, ev) {
    var _ev$target$getBoundin = ev.target.getBoundingClientRect(),
        x = _ev$target$getBoundin.x,
        y = _ev$target$getBoundin.y,
        height = _ev$target$getBoundin.height,
        width = _ev$target$getBoundin.width;

    setActiveBodyPartCoordinat({
      pageX: x + width / 2,
      pageY: y + height / 2
    });
    setCurrentBodyPartId(bodyPartId);
    setIsOpenOrgan(true);
  };

  var handleOpenIllnessesListPage = function handleOpenIllnessesListPage(bodyPartId) {
    history.push({
      pathname: IlnessesListRoute.rawUrl({
        bodyPartId: bodyPartId,
        genderId: genderId,
        ageId: ageId
      }),
      state: {
        from: history.location.pathname
      }
    });
  };

  var handleCloseOrganModal = function handleCloseOrganModal() {
    setIsOpenOrgan(false);
  };

  var handleCloseFeatureModal = function handleCloseFeatureModal() {
    setIsHintShowing('features', false);
  };

  var getOrganName = function getOrganName(item) {
    return item.diseaseButtonCaption || item.caption;
  };

  var getOrganDescription = function getOrganDescription(item) {
    return item.diseaseDescription;
  };

  if (!ageId || !genderId) {
    history.push(MainRoute.rawUrl());
  }

  useEffect(function () {
    if (currentAgeMenu) {
      var image = genderId === 1 ? currentAgeMenu.mimage : currentAgeMenu.wimage;
      setBodyUrl("".concat(baseImgUrl).concat(image));
    }
  }, [genderId, currentAgeMenu]);
  useEffect(function () {
    if (extraMenuList.length > 0) {
      var extraIndex = getExtraMenuIndex(ExtraMenuTypes.ALL_SICKNESS);

      if (extraIndex !== -1) {
        setInitialSlide(extraIndex);
      }
    }
  }, [extraMenuList, getExtraMenuIndex]);
  useEffect(function () {
    setState({
      ageId: ageId,
      genderId: genderId
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0427\u0442\u043E \u0432\u0430\u043C \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u043D\u043E")), React.createElement(ExtraMenu, {
    items: extraMenuList,
    initialSlide: initialSlide,
    history: history
  }), React.createElement(Container, null, React.createElement("div", null, React.createElement(Title, null, "\u0427\u0442\u043E \u0432\u0430\u043C \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u043D\u043E?"), React.createElement(Description, null, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0440\u0433\u0430\u043D \u0438\u043B\u0438 \u0441\u0438\u0441\u0442\u0435\u043C\u0443, \u0447\u0442\u043E\u0431\u044B", React.createElement("br", null), "\u0443\u0437\u043D\u0430\u0442\u044C \u043E \u0440\u0438\u0441\u043A\u0430\u0445, \u0437\u0430\u0431\u043E\u043B\u0435\u0432\u0430\u043D\u0438\u044F\u0445", React.createElement("br", null), "\u0438 \u043A\u0430\u043A \u0438\u0445 \u0438\u0437\u0431\u0435\u0436\u0430\u0442\u044C, \u043D\u0430\u0436\u0430\u0432", React.createElement("br", null), "\u043D\u0430 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044E\u0449\u0443\u044E \u0442\u043E\u0447\u043A\u0443")), React.createElement(BodyMapContainer, null, React.createElement(BodyMap, {
    bodyUrl: bodyUrl,
    dotPosition: initialDotPosition,
    bodyParts: bodyState.bodyParts,
    onCursorClick: handleBodyPartClick
  })), React.createElement(OrganHint, {
    bodyParts: bodyState.bodyParts,
    currentBodyPartId: currentBodyPartId,
    isOpen: isOpenOrgan,
    onOpen: handleOpenIllnessesListPage,
    onClose: handleCloseOrganModal,
    bodyPartCoordinat: activeBodyPartCoordinat,
    getOrganName: getOrganName,
    getOrganDescription: getOrganDescription
  }), React.createElement(FeaturesHint, {
    bodyParts: bodyState.bodyParts,
    isOpen: hintShowing.features,
    onClose: handleCloseFeatureModal
  }), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434"), React.createElement(IconButton, {
    iconLink: HouseIcon,
    onClick: handleOpenMainPage
  }, "\u0414\u043E\u043C\u043E\u0439"), React.createElement(IconButton, {
    iconLink: MenuIcon,
    onClick: handleGenderMenuRouteOpen
  }, "\u041C\u0435\u043D\u044E"))));
};

export default Anatomy;