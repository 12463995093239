import { Environment } from 'src/services';
export var getImageLink = function getImageLink(url) {
  var isFullLink = /http/.test(url);

  if (!isFullLink && url) {
    return "".concat(Environment.BaseImageURL).concat(url);
  }

  return url;
};
export var getAPIUrl = function getAPIUrl(apiUrl) {
  return "".concat(Environment.API_URL).concat(apiUrl);
};