export var createModalRootNode = function createModalRootNode() {
  var modalRoot = document.getElementById('modal-root');

  if (!modalRoot) {
    var node = document.createElement('div');
    node.setAttribute('id', 'modal-root');
    document.body.appendChild(node);
    modalRoot = document.getElementById('modal-root');
  }

  return modalRoot;
};