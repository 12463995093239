import styled from 'styled-components';
import HealthIcon from 'src/assets/icons/health-icon.png';
import PetalIcon from 'src/assets/petal.png';
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1isw6db-0"
})(["display:flex;flex-direction:column;width:571px;height:428px;"]);
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-1isw6db-1"
})(["flex-grow:1;font-size:24px;line-height:34px;padding:90px 40px 0 40px;"]);
export var ButtonContainer = styled.div.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-1isw6db-2"
})(["display:flex;align-items:center;justify-content:space-between;button{width:143px;height:58px;font-size:20px;margin:20px 0;}"]);
export var HintContainer = styled.div.withConfig({
  displayName: "styles__HintContainer",
  componentId: "sc-1isw6db-3"
})(["position:absolute;right:-62.5%;top:73px;"]);
export var CenterBlock = styled.div.withConfig({
  displayName: "styles__CenterBlock",
  componentId: "sc-1isw6db-4"
})(["background-color:", ";border-radius:50%;border:6px solid ", ";box-shadow:0 0 30px 0 ", ";width:280px;height:280px;margin:0 auto;text-align:center;color:", ";font-family:KievitPro;font-size:26px;line-height:30px;font-weight:bold;text-transform:uppercase;text-align:center;position:relative;overflow:hidden;cursor:pointer;"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.blue;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.blue;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
});
export var CenterBlockTitle = styled.div.withConfig({
  displayName: "styles__CenterBlockTitle",
  componentId: "sc-1isw6db-5"
})(["margin:0 auto;width:90%;"]);
export var DatePickerIcon = styled.div.withConfig({
  displayName: "styles__DatePickerIcon",
  componentId: "sc-1isw6db-6"
})(["background-image:url(\"", "\");background-repeat:no-repeat;background-size:cover;background-position:center;width:25%;height:25%;display:block;margin:20% auto 20px;"], HealthIcon);
export var TitleContainer = styled.div.withConfig({
  displayName: "styles__TitleContainer",
  componentId: "sc-1isw6db-7"
})(["display:flex;align-items:baseline;justify-content:center;font-size:25px;padding-bottom:41px;border-bottom:1px solid ", ";"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.blue;
});
export var SkipButton = styled.div.withConfig({
  displayName: "styles__SkipButton",
  componentId: "sc-1isw6db-8"
})(["opacity:0.7;font-size:20px;transition:0.3s ease-in-out opacity;cursor:pointer;&:hover{opacity:1;}"]);
export var BackButton = styled.div.withConfig({
  displayName: "styles__BackButton",
  componentId: "sc-1isw6db-9"
})(["display:flex;align-items:center;justify-content:center;width:143px;height:58px;font-size:20px;margin:20px 0;border:1px solid ", ";border-radius:8px;color:", ";transition:0.3s ease-in-out color;cursor:pointer;&:hover{color:", ";}svg{width:12px;height:12px;fill:", ";margin-right:7px;}"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.gray300;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.gray300;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.white;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.gray300;
});
export var BacklitPetal = styled.div.withConfig({
  displayName: "styles__BacklitPetal",
  componentId: "sc-1isw6db-10"
})(["width:644px;height:753px;position:absolute;right:-768px;top:-277px;background-image:url(\"", "\");background-repeat:no-repeat;background-size:contain;transform:rotate(-2deg);"], PetalIcon);