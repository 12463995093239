import styled, { css } from 'styled-components';
import HealthIcon from 'src/assets/icons/health-icon.png';
export var ZozhItemWrapper = styled.ul.withConfig({
  displayName: "styles__ZozhItemWrapper",
  componentId: "g8847h-0"
})(["position:relative;"]);
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "g8847h-1"
})(["position:relative;width:41em;height:41em;border:5px solid ", ";box-shadow:0px 0px 90px 23px ", ";background-color:", ";border-radius:50%;overflow:hidden;transform:scale(1);"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.opacity_blue;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.opacity_blue;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.opacity_blue_55;
});
export var Image = styled.div.withConfig({
  displayName: "styles__Image",
  componentId: "g8847h-2"
})(["display:block;font-size:1.2em;height:11.2em;width:11.5em;position:absolute;bottom:-2em;right:-1.9em;transform:skew(-38.5deg) rotate(-70deg) scale(1);background-repeat:no-repeat;background-size:contain;", ""], function (_ref4) {
  var src = _ref4.src,
      zoom = _ref4.zoom,
      posX = _ref4.posX,
      posY = _ref4.posY;
  return css(["", ";", " ", ""], src && css(["background-image:url(", ");"], src), zoom && css(["background-size:", "%;"], zoom), (posX || posY) && css(["background-position:", "px ", "px;"], posX, posY));
});
export var ZozhItem = styled.li.withConfig({
  displayName: "styles__ZozhItem",
  componentId: "g8847h-3"
})(["position:absolute;font-size:1.5em;width:15em;height:15em;overflow:hidden;transform-origin:100% 100%;border:11px solid transparent;transition:all 1s ease;left:50%;top:50%;margin-top:-2em;margin-left:-15em;overflow:hidden;cursor:pointer;:hover{opacity:0.5;}"]);
export var CenterBlock = styled.div.withConfig({
  displayName: "styles__CenterBlock",
  componentId: "g8847h-4"
})(["position:relative;top:50%;width:44%;height:44%;margin:0 auto;background-color:", ";border-radius:50%;border:8px solid ", ";box-shadow:0 0 30px 0 ", ";color:", ";transform:translateY(-50%);overflow:hidden;cursor:pointer;"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.blue;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.white;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.blue;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.white;
});
export var CenterBlockTitle = styled.div.withConfig({
  displayName: "styles__CenterBlockTitle",
  componentId: "g8847h-5"
})(["margin:0 auto;width:70%;font-family:", ";font-size:26px;line-height:30px;font-weight:bold;text-transform:uppercase;text-align:center;"], function (_ref9) {
  var theme = _ref9.theme;
  return theme.fonts.kievit;
});
export var DatePickerIcon = styled.div.withConfig({
  displayName: "styles__DatePickerIcon",
  componentId: "g8847h-6"
})(["background-image:url(\"", "\");background-repeat:no-repeat;background-size:cover;background-position:center;width:25%;height:25%;display:block;margin:20% auto 20px;"], HealthIcon);
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "g8847h-7"
})(["color:", ";font-family:", ";font-size:36px;font-weight:500;position:absolute;padding-bottom:19px;border-bottom:4px solid ", ";cursor:pointer;&:before{content:'';display:block;width:24px;height:24px;position:absolute;bottom:-13px;background-color:", ";border-radius:50%;border:4px solid ", ";}"], function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.white;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.fonts.kievit;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.opacity_blue;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.white;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.opacity_blue;
});
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "g8847h-8"
})(["display:flex;align-items:center;justify-content:center;top:189px;position:relative;", "{:nth-child(1){top:-35px;left:calc(50% + 110px);padding-left:100px;&:before{left:0;}}:nth-child(2){top:140px;left:calc(50% + 290px);padding-left:100px;&:before{left:0;}}:nth-child(3){top:325px;left:calc(50% + 310px);padding-left:100px;&:before{left:0;}}:nth-child(4){top:550px;left:calc(50% + 150px);padding-left:150px;&:before{left:0;}}:nth-child(5){top:535px;right:calc(50% + 170px);padding-right:150px;&:before{right:0;}}:nth-child(6){top:325px;right:calc(50% + 310px);padding-right:100px;&:before{right:0;}}:nth-child(7){top:140px;right:calc(50% + 290px);padding-right:120px;&:before{right:0;}}}", "{:nth-child(1){transform:rotate(64.5deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-75deg) scale(1);}}:nth-child(2){transform:rotate(116deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-116deg) scale(1);}}:nth-child(3){transform:rotate(167.5deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-161.5deg) scale(1);}}:nth-child(4){transform:rotate(219deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-219deg) scale(1);}}:nth-child(5){transform:rotate(270.5deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-270.5deg) scale(1);}}:nth-child(6){transform:rotate(322deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-322deg) scale(1);}}:nth-child(7){transform:rotate(13deg) skew(38.5deg);", "{transform:skew(-38.5deg) rotate(-14deg) scale(1);}}}"], Text, ZozhItem, Image, Image, Image, Image, Image, Image, Image);