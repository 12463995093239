import styled, { keyframes } from 'styled-components';
var Translate = keyframes(["0%{transform:translateY(-90px);}100%{transform:translateY(35px);}"]);
export var ModalWrapper = styled.div.withConfig({
  displayName: "styles__ModalWrapper",
  componentId: "sc-1vgzekx-0"
})(["padding:0 25px 25px 0;"]);
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-1vgzekx-1"
})(["text-align:center;font-size:2rem;"]);
export var ArrowContainer = styled.div.withConfig({
  displayName: "styles__ArrowContainer",
  componentId: "sc-1vgzekx-2"
})(["position:absolute;left:calc(50% - 50px);bottom:-265px;width:100px;transform:rotate(180deg);"]);
export var Arrow = styled.img.withConfig({
  displayName: "styles__Arrow",
  componentId: "sc-1vgzekx-3"
})(["width:100%;height:100%;display:block;animation:", " ease-in-out 1.5s infinite;"], Translate);