function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import axios from 'axios';
import { AuthService } from './auth.service';
import { metricsToken } from './environment';

function getToken() {
  var token = AuthService.getToken();

  if (token) {
    return token;
  }

  throw new Error('Токен не найден, перезагрузите страницу');
}

function createHeaders(headers) {
  var noToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!noToken) {
    var token = getToken();
    return _objectSpread({
      token: token
    }, headers);
  }

  return _objectSpread({}, headers, {
    Authorization: "Bearer ".concat(metricsToken) // 'Cache-Control': 'no-cache',
    // Pragma: 'no-cache',
    // 'Content-Security-Policy': '*',

  });
}

function getData(_ref) {
  var url = _ref.url,
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$headers = _ref.headers,
      headers = _ref$headers === void 0 ? {} : _ref$headers,
      _ref$noToken = _ref.noToken,
      noToken = _ref$noToken === void 0 ? true : _ref$noToken;
  return axios(_objectSpread({
    url: url,
    method: 'get',
    responseType: 'json',
    headers: createHeaders(headers, noToken)
  }, params));
}

function postData(_ref2) {
  var url = _ref2.url,
      _ref2$params = _ref2.params,
      params = _ref2$params === void 0 ? {} : _ref2$params,
      _ref2$data = _ref2.data,
      inData = _ref2$data === void 0 ? {} : _ref2$data,
      _ref2$headers = _ref2.headers,
      headers = _ref2$headers === void 0 ? {} : _ref2$headers,
      _ref2$noToken = _ref2.noToken,
      noToken = _ref2$noToken === void 0 ? false : _ref2$noToken;
  var data = inData.constructor === Object ? _objectSpread({}, inData) : _toConsumableArray(inData);
  return axios(_objectSpread({
    url: url,
    method: 'post',
    responseType: 'json',
    headers: createHeaders(headers, noToken),
    data: data
  }, params));
}

function putData(_ref3) {
  var url = _ref3.url,
      _ref3$params = _ref3.params,
      params = _ref3$params === void 0 ? {} : _ref3$params,
      _ref3$data = _ref3.data,
      data = _ref3$data === void 0 ? {} : _ref3$data,
      _ref3$headers = _ref3.headers,
      headers = _ref3$headers === void 0 ? {} : _ref3$headers,
      _ref3$noToken = _ref3.noToken,
      noToken = _ref3$noToken === void 0 ? false : _ref3$noToken;
  return axios(_objectSpread({
    url: url,
    method: 'put',
    responseType: 'json',
    headers: createHeaders(headers, noToken),
    data: _objectSpread({}, data)
  }, params));
}

function deleteData(_ref4) {
  var url = _ref4.url,
      _ref4$params = _ref4.params,
      params = _ref4$params === void 0 ? {} : _ref4$params,
      _ref4$data = _ref4.data,
      data = _ref4$data === void 0 ? {} : _ref4$data,
      _ref4$headers = _ref4.headers,
      headers = _ref4$headers === void 0 ? {} : _ref4$headers,
      _ref4$noToken = _ref4.noToken,
      noToken = _ref4$noToken === void 0 ? false : _ref4$noToken;
  return axios(_objectSpread({
    url: url,
    method: 'delete',
    responseType: 'json',
    headers: createHeaders(headers, noToken),
    data: _objectSpread({}, data)
  }, params));
}

export var HttpService = function HttpService() {
  _classCallCheck(this, HttpService);
};

_defineProperty(HttpService, "getData", getData);

_defineProperty(HttpService, "postData", postData);

_defineProperty(HttpService, "putData", putData);

_defineProperty(HttpService, "deleteData", deleteData);