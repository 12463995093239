var _genderData, _genderDataMenu;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var GENDER_TYPES = {
  male: 1,
  female: 2
};
export var genderData = (_genderData = {}, _defineProperty(_genderData, GENDER_TYPES.male, {
  1: 'Мальчики',
  2: 'Мальчики',
  3: 'Мальчики',
  4: 'Мальчики',
  5: 'Мальчики',
  6: 'Юноши',
  7: 'Мужчины'
}), _defineProperty(_genderData, GENDER_TYPES.female, {
  1: 'Девочки',
  2: 'Девочки',
  3: 'Девочки',
  4: 'Девочки',
  5: 'Девочки',
  6: 'Девушки',
  7: 'Женщины'
}), _genderData);
export var genderDataMenu = (_genderDataMenu = {}, _defineProperty(_genderDataMenu, GENDER_TYPES.male, {
  1: 'Мальчики \n от 0 до 1 года',
  2: 'Мальчики \n от 1 до 3 лет',
  3: 'Мальчики \n от 4 до 6 лет',
  4: 'Мальчики \n от 7 до 11 лет',
  5: 'Мальчики \n от 12 до 14 лет',
  6: 'Юноши \n от 15 до 18 лет',
  7: 'Мужчины'
}), _defineProperty(_genderDataMenu, GENDER_TYPES.female, {
  1: 'Девочки \n от 0 до 1 года',
  2: 'Девочки \n от 1 до 3 лет',
  3: 'Девочки \n от 4 до 6 лет',
  4: 'Девочки \n от 7 до 11 лет',
  5: 'Девочки \n от 12 до 14 лет',
  6: 'Девушки \n от 15 до 18 лет',
  7: 'Женщины'
}), _genderDataMenu);