import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1bbznbq-0"
})(["text-align:center;padding-top:3%;"]);
export var TitleText = styled.h1.withConfig({
  displayName: "styles__TitleText",
  componentId: "sc-1bbznbq-1"
})(["color:", ";font-family:", ";font-weight:bold;font-size:54px;line-height:65px;text-shadow:0px 0px 64px rgba(60,170,255,0.8);white-space:pre-line;margin:0;"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fonts.kievit;
});