import styled from 'styled-components';
import { Wrapper } from 'src/styles/utilities/helpers';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-354get-0"
})(["", ";flex-direction:column;padding-top:84px;"], Wrapper());
export var Footer = styled.div.withConfig({
  displayName: "styles__Footer",
  componentId: "sc-354get-1"
})(["background:", ";display:flex;justify-content:space-between;align-items:center;height:128px;padding:20px 75px;"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
});
export var LogoImage = styled.img.withConfig({
  displayName: "styles__LogoImage",
  componentId: "sc-354get-2"
})([""]);
export var ButtonWrapper = styled.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-354get-3"
})(["position:fixed;display:flex;justify-content:space-between;right:75px;bottom:75px;left:75px;"]);
export var QuizResultWrapper = styled.div.withConfig({
  displayName: "styles__QuizResultWrapper",
  componentId: "sc-354get-4"
})(["display:flex;flex-direction:column;justify-content:center;max-width:1200px;width:100%;height:100%;margin:15% auto 0;"]);
export var QuizResultTitle = styled.div.withConfig({
  displayName: "styles__QuizResultTitle",
  componentId: "sc-354get-5"
})(["font-size:3rem;color:", ";text-align:center;margin-bottom:", ";"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var noMargin = _ref3.noMargin;
  return noMargin ? '0' : '60px';
});
export var QuizResultCaption = styled.div.withConfig({
  displayName: "styles__QuizResultCaption",
  componentId: "sc-354get-6"
})(["font-size:2.4rem;color:", ";text-align:center;margin-top:20px;margin-bottom:60px;"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
});
export var QuizResultButtonWrapper = styled.div.withConfig({
  displayName: "styles__QuizResultButtonWrapper",
  componentId: "sc-354get-7"
})(["display:flex;justify-content:center;button{min-width:270px;justify-content:center;margin-right:20px;&:last-child{margin:0;}}"]);