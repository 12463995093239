import styled from 'styled-components';
import { Wrapper } from 'src/styles/utilities/helpers';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1spe1ku-0"
})(["", ""], Wrapper());
export var Side = styled.div.withConfig({
  displayName: "styles__Side",
  componentId: "sc-1spe1ku-1"
})(["width:100%;max-width:calc(50% - 5px);"]);
export var LeftSide = styled(Side).withConfig({
  displayName: "styles__LeftSide",
  componentId: "sc-1spe1ku-2"
})([""]);
export var RightSide = styled(Side).withConfig({
  displayName: "styles__RightSide",
  componentId: "sc-1spe1ku-3"
})([""]);
export var BackButtonWrapper = styled.div.withConfig({
  displayName: "styles__BackButtonWrapper",
  componentId: "sc-1spe1ku-4"
})(["position:fixed;left:75px;bottom:75px;"]);