import React from 'react';
import { Button } from './styles';
export var TransparentButton = function TransparentButton(_ref) {
  var className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick;
  return React.createElement(Button, {
    className: className,
    onClick: onClick
  }, children);
};