import React, { useCallback } from 'react';
import { Container, AnswerContainer, AnswerItem, Title } from './styles';
import Pagination from './pagination';
import Message from './message';
import { answers } from './consts';
var InitialQuiestion = {
  id: 0,
  question: '',
  answer1: '',
  answer2: '',
  answer3: '',
  answer4: '',
  correctAnswer: 0,
  correctAnswerText: '',
  incorrectAnswerText: '',
  pos: 0,
  quizId: 0
};

var Quiz = function Quiz(props) {
  var items = props.items,
      currentItemIndex = props.currentItemIndex,
      answeredQuestions = props.answeredQuestions,
      questionMessage = props.questionMessage,
      onAnswerSelect = props.onAnswerSelect,
      ageId = props.ageId;

  var onAnswerClick = function onAnswerClick(ev) {
    var data = ev.currentTarget.dataset;
    var answerId = Number(data.answerId) || 0;

    if (getAnsweredQuestion() === null) {
      onAnswerSelect(answerId);
    }
  };

  var getCurrentQuestion = useCallback(function () {
    return items[currentItemIndex] || InitialQuiestion;
  }, [currentItemIndex, items]);
  var getAnsweredQuestion = useCallback(function () {
    return answeredQuestions[currentItemIndex] || null;
  }, [answeredQuestions, currentItemIndex]);
  var canShowMessage = useCallback(function () {
    return !!questionMessage.correctAnswerText || !!questionMessage.incorrectAnswerText;
  }, [questionMessage.correctAnswerText, questionMessage.incorrectAnswerText]);
  return React.createElement(Container, null, React.createElement(Pagination, {
    items: items,
    activeItem: currentItemIndex
  }), canShowMessage() && React.createElement(Message, {
    questionMessage: questionMessage,
    ageId: ageId
  }), !canShowMessage() && React.createElement(React.Fragment, null, React.createElement(Title, null, getCurrentQuestion().question), React.createElement(AnswerContainer, null, answers.map(function (_ref) {
    var id = _ref.id,
        title = _ref.title;
    return React.createElement(AnswerItem, {
      key: "answer-".concat(currentItemIndex, "-").concat(id),
      "data-answer-id": id,
      active: getAnsweredQuestion() === id,
      onClick: onAnswerClick
    }, getCurrentQuestion()[title]);
  }))));
};

export default Quiz;