import styled from 'styled-components';
export var Arrows = styled.button.withConfig({
  displayName: "styles__Arrows",
  componentId: "sc-1fr0153-0"
})(["position:absolute;z-index:20;right:16px;padding:12px;background:transparent;border:0;cursor:pointer;"]);
export var TopArrow = styled(Arrows).withConfig({
  displayName: "styles__TopArrow",
  componentId: "sc-1fr0153-1"
})(["top:", ";"], function (_ref) {
  var withTopMenu = _ref.withTopMenu;
  return withTopMenu ? '91px' : '9px';
});
export var BottomArrow = styled(Arrows).withConfig({
  displayName: "styles__BottomArrow",
  componentId: "sc-1fr0153-2"
})(["bottom:", "px;"], function (_ref2) {
  var withBottomShift = _ref2.withBottomShift;
  return withBottomShift ? 76 : 9;
});
export var ContentWrapper = styled.div.withConfig({
  displayName: "styles__ContentWrapper",
  componentId: "sc-1fr0153-3"
})(["width:", ";"], function (_ref3) {
  var hasScroll = _ref3.hasScroll;
  return hasScroll ? 'calc(100% - 80px)' : '100%';
});