import * as Environment from './environment';
import * as UI from './ui.service';
export { Environment, UI };
export * from './http.service';
export * from './date-time.service';
export * from './storage.service';
export * from './auth.service';
export * from './body-part.service';
export * from './context.service';
export * from './sickness.service';
export * from './email.service';
export * from './utils';
export var BackgroundTypes;

(function (BackgroundTypes) {
  BackgroundTypes["image"] = "image";
  BackgroundTypes["color"] = "color";
})(BackgroundTypes || (BackgroundTypes = {}));