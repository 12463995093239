import styled from 'styled-components';
import { Wrapper } from 'styles/utilities/helpers';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-7hj9fl-0"
})(["", ""], Wrapper());
export var LeftSide = styled.div.withConfig({
  displayName: "styles__LeftSide",
  componentId: "sc-7hj9fl-1"
})(["width:50%;"]);
export var RightSide = styled.div.withConfig({
  displayName: "styles__RightSide",
  componentId: "sc-7hj9fl-2"
})(["width:50%;padding:40px 75px 40px 70px;color:", ";"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
});
export var Header = styled.div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-7hj9fl-3"
})(["display:flex;flex-wrap:wrap;margin-bottom:35px;"]);
export var Title = styled.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-7hj9fl-4"
})(["position:relative;margin-bottom:15px;margin-left:-2px;font-size:26px;font-weight:normal;text-transform:uppercase;&:before{content:'';display:block;width:100%;box-shadow:0 10px 35px 10px ", ";}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.opacity_blue;
});
export var SubTitle = styled.div.withConfig({
  displayName: "styles__SubTitle",
  componentId: "sc-7hj9fl-5"
})(["flex:1 1 100%;font-size:30pt;font-weight:bold;text-indent:-3px;&:after{content:'';display:block;width:52px;height:5px;margin-top:15px;border-radius:2px;background-color:", ";}"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.blue;
});
export var Main = styled.div.withConfig({
  displayName: "styles__Main",
  componentId: "sc-7hj9fl-6"
})(["button{width:100%;margin-bottom:20px;font-size:1.3rem;&:last-child{margin-bottom:0;}}"]);
export var BackButtonWrapper = styled.div.withConfig({
  displayName: "styles__BackButtonWrapper",
  componentId: "sc-7hj9fl-7"
})(["position:fixed;left:75px;bottom:75px;"]);
export var ButtonContainer = styled.div.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-7hj9fl-8"
})(["display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;button{width:calc(33.3% - 25px);height:190px;margin:0 25px 25px 0;}"]);
export var ModalHiddenContent = styled.div.withConfig({
  displayName: "styles__ModalHiddenContent",
  componentId: "sc-7hj9fl-9"
})(["overflow:hidden;height:100%;width:70%;margin:0 auto;& embed{margin:-56px 0 0;}"]);