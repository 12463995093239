import styled, { css } from 'styled-components';
import { typeBase } from 'styles/utilities/typography';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1mtdgng-0"
})(["display:flex;justify-content:flex-start;align-items:", ";position:fixed;top:0;left:0;width:100%;height:100%;z-index:1000;", ""], function (_ref) {
  var isLeft = _ref.isLeft,
      isRight = _ref.isRight;
  return isLeft || isRight ? 'flex-start' : 'center';
}, function (_ref2) {
  var hidden = _ref2.hidden;
  return hidden && css(["pointer-events:none;"]);
});
export var Overlay = styled.div.withConfig({
  displayName: "styles__Overlay",
  componentId: "sc-1mtdgng-1"
})(["position:fixed;top:0;left:0;width:100%;height:100%;", ";z-index:-1;overflow:hidden;transition:all 0.2s ease-in-out;"], function (_ref3) {
  var hidden = _ref3.hidden,
      theme = _ref3.theme;
  return css(["background-color:", ";pointer-events:", ";"], hidden ? 'transparent' : theme.colors.opacity_6_black, hidden ? 'none' : 'all');
});
export var Modal = styled.div.withConfig({
  displayName: "styles__Modal",
  componentId: "sc-1mtdgng-2"
})(["", ";will-change:opacity,transform;pointer-events:none;.modal-back-button{margin-top:50px;}"], function (_ref4) {
  var isLeft = _ref4.isLeft,
      isRight = _ref4.isRight,
      isFullWidth = _ref4.isFullWidth,
      isFullHeightBlock = _ref4.isFullHeightBlock,
      showClose = _ref4.showClose;
  return css(["display:flex;flex-direction:", ";justify-content:", ";", ";", ";", " ", ";margin:", ";"], isRight || isLeft ? 'row' : 'column', isRight ? 'flex-end' : isLeft ? 'flex-start' : 'center', isFullWidth && css(["width:92%;"]), isFullHeightBlock && css(["height:100%;display:block;"]), isFullWidth && showClose && css(["padding-right:30px;"]), isRight && css(["width:100%;"]), isLeft || isRight ? 0 : '0 auto');
});
export var ModalContent = styled.div.withConfig({
  displayName: "styles__ModalContent",
  componentId: "sc-1mtdgng-3"
})(["border-radius:8px;min-width:615px;position:relative;pointer-events:all;", ";"], function (_ref5) {
  var modalHeight = _ref5.modalHeight,
      showClose = _ref5.showClose,
      isLeft = _ref5.isLeft,
      isFullWidth = _ref5.isFullWidth,
      theme = _ref5.theme;
  return css(["background-color:", ";box-shadow:0px 0px 63px 7px ", ";color:", ";padding:", ";margin-top:", ";margin-left:", ";margin-right:", ";", " ", ";", ""], theme.colors.opacity_mainDarkBlue, theme.colors.opacity_blue, theme.colors.white, isLeft ? '35px 10px 35px 50px' : '25px 0px 0px 40px', isLeft ? '303px' : 0, isLeft ? '75px' : 0, isLeft ? '115px' : 0, isFullWidth && css(["width:100%;"]), modalHeight && css(["height:", "px;"], modalHeight), showClose && css(["border-radius:8px 0px 0px 8px;background-color:", ";box-shadow:20px 0px 103px -9px ", ";&:before{content:'';display:block;border-radius:0 0 30px 60px;background-color:transparent;width:50px;height:45px;position:absolute;right:-40px;top:0;box-shadow:inset 21px -10px 0px 0 ", ";}&:after{content:'';display:block;border-radius:0 0 8px 0;background-color:", ";width:42px;height:calc(100% - 35px);position:absolute;right:-40px;bottom:0;}"], theme.colors.modalBg, theme.colors.opacity_blue, theme.colors.modalBg, theme.colors.modalBg));
});
export var Close = styled.div.withConfig({
  displayName: "styles__Close",
  componentId: "sc-1mtdgng-4"
})(["display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:absolute;top:", ";right:", ";padding:15px;border-radius:50%;overflow:hidden;cursor:pointer;pointer-events:all;svg{width:30px;height:30px;fill:", ";}"], function (_ref6) {
  var shiftTop = _ref6.shiftTop;
  return shiftTop ? '41px' : '-30px';
}, function (_ref7) {
  var isFullWidth = _ref7.isFullWidth;
  return isFullWidth ? '-70px' : '-70px';
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.white;
});
export var Title = styled.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1mtdgng-5"
})(["", ";margin-bottom:20px;text-align:center;color:", ";"], typeBase('2.8rem'), function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.white;
});