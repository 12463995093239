import styled, { css } from 'styled-components';
import OkIcon from 'src/assets/icons/ok-in-round.svg';
import ExclamationIcon from 'src/assets/icons/exclamation-in-round.svg';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "plbuh6-0"
})(["width:100%;height:100%;margin-top:2%;"]);
export var Title = styled.div.withConfig({
  displayName: "styles__Title",
  componentId: "plbuh6-1"
})(["font-size:2.5rem;color:", ";text-align:center;max-width:1000px;width:100%;margin:0 auto 70px;text-shadow:0px 0px 30px rgba(60,170,255,0.8);"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
});
export var PaginationConainer = styled.div.withConfig({
  displayName: "styles__PaginationConainer",
  componentId: "plbuh6-2"
})(["display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;max-width:1000px;width:100%;margin:0 auto;padding:60px 0 70px;text-align:center;"]);
export var PaginationItem = styled.div.withConfig({
  displayName: "styles__PaginationItem",
  componentId: "plbuh6-3"
})(["border-radius:50%;border:1px solid ", ";background-color:", ";color:", ";font-size:1.5rem;font-weight:bold;height:58px;width:58px;padding-top:15px;margin-right:0;position:relative;cursor:default;&:not(:last-child){margin-right:15px;&:after{content:'';background-color:", ";display:block;position:absolute;height:2px;width:15px;top:28px;right:-16px;}}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.blue;
}, function (_ref3) {
  var active = _ref3.active,
      theme = _ref3.theme;
  return active ? theme.colors.blue : 'transparent';
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.blue;
});
export var AnswerContainer = styled.div.withConfig({
  displayName: "styles__AnswerContainer",
  componentId: "plbuh6-4"
})(["display:flex;flex-wrap:wrap;justify-content:space-between;align-items:inherit;margin:0 auto;max-width:1740px;width:100%;"]);
export var AnswerItem = styled.div.withConfig({
  displayName: "styles__AnswerItem",
  componentId: "plbuh6-5"
})(["display:flex;justify-content:center;flex-direction:column;width:calc(50% - 10px);min-height:200px;margin-bottom:20px;padding:20px;color:", ";text-align:center;font-size:2rem;background-color:", ";box-shadow:10px 0px 80px -20px ", ";border:1px solid ", ";cursor:pointer;word-break:break-word;&:nth-child(2n + 1){margin-right:20px;}"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.white;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.modalBg;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.opacity_blue_55;
}, function (_ref9) {
  var active = _ref9.active,
      theme = _ref9.theme;
  return active ? theme.colors.blue : 'transparent';
});
export var MessageContainer = styled.div.withConfig({
  displayName: "styles__MessageContainer",
  componentId: "plbuh6-6"
})(["max-width:1400px;width:100%;margin:0 auto;text-align:center;vertical-align:baseline;"]);
export var MessageTitle = styled.div.withConfig({
  displayName: "styles__MessageTitle",
  componentId: "plbuh6-7"
})(["font-size:4rem;color:", ";text-transform:uppercase;display:inline-block;position:relative;margin:0px 0 60px;padding-left:90px;text-shadow:0px 0px 30px rgba(60,170,255,0.3);&:before{content:'';background-position:center;background-repeat:no-repeat;border-radius:50%;position:absolute;display:inline-block;vertical-align:baseline;left:0;top:6px;height:60px;width:60px;box-shadow:0px 0px 60px -6px ", ";", ";}"], function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.white;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.opacity_blue;
}, function (_ref12) {
  var correct = _ref12.correct;
  return css(["background-image:url(\"", "\");background-size:", ";"], correct ? OkIcon : ExclamationIcon, correct ? '170%' : '120%');
});
export var MessageText = styled.div.withConfig({
  displayName: "styles__MessageText",
  componentId: "plbuh6-8"
})(["color:", ";font-size:2rem;line-height:1.5;"], function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.white;
});