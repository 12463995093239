import styled from 'styled-components';
import Arrow from 'src/assets/icons/arrow.svg';
export var TitleContainer = styled.div.withConfig({
  displayName: "styles__TitleContainer",
  componentId: "xyqdr6-0"
})(["display:flex;align-items:baseline;justify-content:center;font-size:25px;padding-bottom:41px;border-bottom:1px solid ", ";"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.blue;
});
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "xyqdr6-1"
})(["font-size:18px;margin:20px 0;"]);
export var Input = styled.input.withConfig({
  displayName: "styles__Input",
  componentId: "xyqdr6-2"
})(["width:99%;color:", ";padding:25px;margin-bottom:25px;font-size:24px;text-align:center;border:none;border-radius:8px;background:", ";border:", ";caret-color:", ";&:focus,&:active{&::placeholder{opacity:0;}}&::placeholder{font-size:24px;color:", ";opacity:1;transition:box-shadow,0.3s ease-in-out;}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.inputBg;
}, function (_ref4) {
  var isError = _ref4.isError,
      theme = _ref4.theme;
  return isError ? "1px solid ".concat(theme.colors.redBase) : null;
}, function (_ref5) {
  var isCaretHidden = _ref5.isCaretHidden;
  return isCaretHidden ? 'transparent' : 'initial';
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.opacity_blue_55;
});
export var KeyboardContainer = styled.form.withConfig({
  displayName: "styles__KeyboardContainer",
  componentId: "xyqdr6-3"
})(["display:flex;flex-direction:column;align-items:center;.simple-keyboard.hg-theme-default.custom-keyboard{width:785px;margin:30px 0 60px 0;background:transparent;color:", ";font-size:24px;font-weight:bold;.hg-row{&:nth-child(2){justify-content:center;}}.hg-button{flex-grow:0;width:68px;height:70px;padding:25px;margin:5px;transition:box-shadow,0.3s ease-in-out;box-shadow:0px 0px 14px 1px ", ";border-radius:8px;border:none;&:hover{box-shadow:0px 0px 10px 0px ", ";}&.hg-standardBtn{max-width:68px;}&.custom-button{background-color:", ";color:", ";}&.custom-space-button{flex-grow:1;}&.custom-arrow-button{width:110px;span{&:before{content:'';display:block;width:30px;height:36px;background-image:url(", ");background-repeat:no-repeat;background-position:center center;transform:rotate(180deg);}}}&.custom-alt-button{width:134px;}&.custom-bksp-button{width:110px;span{&:before{transform:rotate(90deg);}}}&.custom-shiftactivated-button{width:110px;span{&:before{transform:rotate(0deg);}}}}}"], function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.modalBg;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.opacity_blue_55;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.blueGray50;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.keyboardButton;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.white;
}, Arrow);
export var InputContainer = styled.div.withConfig({
  displayName: "styles__InputContainer",
  componentId: "xyqdr6-4"
})(["position:relative;text-align:center;width:99%;margin-bottom:15px;"]);
export var Error = styled.div.withConfig({
  displayName: "styles__Error",
  componentId: "xyqdr6-5"
})(["width:100%;position:absolute;bottom:0;color:", ";"], function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.redBase;
});
export var RecaptchaWrapper = styled.div.withConfig({
  displayName: "styles__RecaptchaWrapper",
  componentId: "xyqdr6-6"
})(["padding-bottom:15px;"]);