import styled from 'styled-components';
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "tpuuwa-0"
})(["display:flex;flex-flow:column nowrap;justify-content:flex-start;padding-top:", ";width:100%;height:", ";overflow-x:hidden;overflow-y:auto;"], function (_ref) {
  var noShift = _ref.noShift;
  return noShift ? '0' : '84px';
}, function (_ref2) {
  var fullHeight = _ref2.fullHeight;
  return fullHeight ? '100vh' : 'calc(100% - 84px)';
});
export var Footer = styled.div.withConfig({
  displayName: "styles__Footer",
  componentId: "tpuuwa-1"
})(["display:flex;flex-direction:column;align-items:center;padding:75px 0;"]);
export var FooterButtons = styled.div.withConfig({
  displayName: "styles__FooterButtons",
  componentId: "tpuuwa-2"
})(["display:flex;justify-content:start;width:100%;margin-top:30px;padding-left:376px;padding-right:80px;& button{min-width:270px;text-align:center;justify-content:center;&:first-child{margin-right:30px;}}"]);
export var FooterButtonGroup = styled.div.withConfig({
  displayName: "styles__FooterButtonGroup",
  componentId: "tpuuwa-3"
})(["display:flex;margin-right:30px;"]);
export var EmailResultWrapper = styled.div.withConfig({
  displayName: "styles__EmailResultWrapper",
  componentId: "tpuuwa-4"
})(["display:flex;flex-direction:column;justify-content:center;max-width:1000px;width:100%;height:100%;margin:0 auto;"]);
export var EmailResultTitle = styled.div.withConfig({
  displayName: "styles__EmailResultTitle",
  componentId: "tpuuwa-5"
})(["font-size:3rem;color:", ";text-align:center;"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
});
export var EmailResultCaption = styled.div.withConfig({
  displayName: "styles__EmailResultCaption",
  componentId: "tpuuwa-6"
})(["font-size:2.4rem;color:", ";text-align:center;margin-top:20px;margin-bottom:60px;"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
});
export var EmailResultButtonWrapper = styled.div.withConfig({
  displayName: "styles__EmailResultButtonWrapper",
  componentId: "tpuuwa-7"
})(["display:flex;justify-content:center;button{align-items:center;justify-content:center;margin-right:20px;min-width:270px;&:last-child{margin:0;}}"]);
export var EmailResultFooter = styled.div.withConfig({
  displayName: "styles__EmailResultFooter",
  componentId: "tpuuwa-8"
})(["background:", ";display:flex;justify-content:space-between;align-items:center;height:128px;padding:20px 75px;"], function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.white;
});
export var LogoImage = styled.img.withConfig({
  displayName: "styles__LogoImage",
  componentId: "tpuuwa-9"
})([""]);
export var LearnMoreButtonWrapper = styled.div.withConfig({
  displayName: "styles__LearnMoreButtonWrapper",
  componentId: "tpuuwa-10"
})(["min-width:270px;div{margin-left:0;}"]);