/* code from https://github.com/BuZZ-dEE/uuid-generator-ts */
export var uuidGenerator = function uuidGenerator() {
  // ex: http://stackoverflow.com/a/8809472/188246
  var d = new Date().getTime();

  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    /* tslint:disable:no-bitwise */
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    /* tslint:enable:no-bitwise */
  });
};