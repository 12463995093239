function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'src/services/context.service';
import Title from 'components/title';
import AgeSelector from 'components/age-selection';
import { GenderRoute } from 'src/utils/const';
import { SubTitle } from './styles';
export default function Main(_ref) {
  var history = _ref.history;

  var _useContext = useContext(AppContext),
      ageMenu = _useContext.ageMenu,
      setIsLoadedZogMenu = _useContext.setIsLoadedZogMenu,
      setState = _useContext.setState;

  var handlerSelectAge = function handlerSelectAge(state) {
    if (state) {
      setState(_objectSpread({}, state, {
        genderId: null
      }));
      setIsLoadedZogMenu(false);
      history.push(GenderRoute.rawUrl(_objectSpread({}, state)));
    }
  };

  return React.createElement(Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0413\u043B\u0430\u0432\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430")), React.createElement(Title, null), React.createElement(SubTitle, null, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u043E\u0437\u0440\u0430\u0441\u0442, \u043F\u043E\u043B \u0438 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u0443\u044E\u0449\u0438\u0439 \u0412\u0430\u0441 \u0440\u0430\u0437\u0434\u0435\u043B"), React.createElement(AgeSelector, {
    selectAge: handlerSelectAge,
    ageMenu: ageMenu
  }));
}