function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from 'src/services';
import { LandingRoute, GenderMenuRoute, MainRoute } from 'src/utils/const';
import ExtraMenu from 'components/extra-menu';
import { PreviewSections, getInitialPreviewBlocks } from 'components/preview-sections';
import { ExtraMenuTypes } from 'components/extra-menu/interface';
import { BackButton } from 'components/button';
import { BackButtonWrapper } from 'components/illnesses-list-view/styles';
import { ScrollBar } from 'components/scrollbar';
import Hint from 'components/hint';

var Landings = function Landings(props) {
  var params = props.match.params,
      history = props.history;
  var ageId = Number(params.ageId);
  var genderId = Number(params.genderId);
  var landingId = Number(params.landingId);

  var _useContext = useContext(AppContext),
      appContext = _useContext.appContext,
      setState = _useContext.setState,
      extraMenuList = _useContext.extraMenuList,
      extraMenu = _useContext.extraMenu;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      previewBlocks = _useState2[0],
      setPreviewBlocks = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      initialSlide = _useState4[0],
      setInitialSlide = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      showEmailResult = _useState6[0],
      setShowEmailResult = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      onlyPreview = _useState8[0],
      setOnlyPreview = _useState8[1];

  var handleGenderMenuOpen = function handleGenderMenuOpen() {
    history.push(GenderMenuRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var handleBackClick = function handleBackClick() {
    history.goBack();
  };

  var handleGoMainPage = function handleGoMainPage() {
    history.push(MainRoute.rawUrl());
  };

  var getPageTitle = useCallback(function () {
    var result = 'Статический лендинг';
    var currentLanding = extraMenuList.filter(function (item) {
      return item.type === ExtraMenuTypes.EXTRA_MENU;
    }).find(function (item) {
      return item.id === landingId;
    });

    if (currentLanding && currentLanding.name) {
      result = currentLanding.name;
    }

    return result;
  }, [extraMenuList, landingId]);

  var handleOnlyPreview = function handleOnlyPreview() {
    var hide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    setOnlyPreview(hide);
  };

  useEffect(function () {
    if (ageId && genderId) {
      if (appContext.ageId !== ageId && appContext.genderId !== genderId) {
        setState({
          ageId: ageId,
          genderId: genderId
        });
      }
    }

    if (extraMenu.length > 0) {
      var _extraMenu$filter = extraMenu.filter(function (extra) {
        return extra.id === landingId;
      }),
          _extraMenu$filter2 = _slicedToArray(_extraMenu$filter, 1),
          blocks = _extraMenu$filter2[0];

      if (blocks && blocks.items.length > 0) {
        setPreviewBlocks(getInitialPreviewBlocks({
          value: blocks.items
        }));
      }

      if (landingId) {
        var extraIndex = extraMenuList.map(function (item) {
          return item.type === ExtraMenuTypes.EXTRA_MENU ? item : {
            id: 0
          };
        }).findIndex(function (item) {
          return item.id === landingId;
        });

        if (extraIndex !== -1) {
          setInitialSlide(extraIndex);
        }
      }
    }
  }, [ageId, appContext.ageId, appContext.genderId, extraMenu, extraMenuList, genderId, landingId, setState]);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, getPageTitle())), !showEmailResult && React.createElement(ExtraMenu, {
    items: extraMenuList,
    history: history,
    initialSlide: initialSlide
  }), React.createElement(ScrollBar, {
    withTopMenu: true,
    landingId: landingId
  }, React.createElement(PreviewSections, {
    blocks: previewBlocks,
    handleGenderMenuOpen: handleGenderMenuOpen,
    handleHomePageOpen: handleGoMainPage,
    handleOnlyPreview: handleOnlyPreview,
    currentLandingUrl: LandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId,
      landingId: landingId
    }),
    showEmailResult: showEmailResult,
    setShowEmailResult: setShowEmailResult,
    setState: setState
  })), !onlyPreview && !showEmailResult && React.createElement(React.Fragment, null, React.createElement(Hint, null), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434"))));
};

export default Landings;