import React from 'react';
import { BackButton, IconButton } from 'components/button';
import HouseIcon from 'src/assets/icons/house.png';
import MenuIcon from 'src/assets/icons/menu.svg';
import MZLogo from 'src/assets/mz-logo.png';
import FFLogo from 'src/assets/ff-logo.png';
import AlfaLogo from 'src/assets/alfa-logo.png';
import { EmailResultButtonWrapper, EmailResultTitle, EmailResultWrapper, EmailResultFooter, LogoImage, EmailResultCaption } from './styles';

var EmailResult = function EmailResult(props) {
  var onHomePageOpen = props.onHomePageOpen,
      onBack = props.onBack,
      onGenderMenuRouteOpen = props.onGenderMenuRouteOpen,
      title = props.title,
      email = props.email;
  return React.createElement(React.Fragment, null, React.createElement(EmailResultWrapper, null, React.createElement(EmailResultTitle, null, title || 'Мы отправили информацию на Ваш адрес'), React.createElement(EmailResultCaption, null, "\u0412\u0430\u0448 \u0430\u0434\u0440\u0435\u0441: ", email), React.createElement(EmailResultButtonWrapper, null, React.createElement(BackButton, {
    onClick: onBack
  }, "\u041D\u0430\u0437\u0430\u0434"), React.createElement(IconButton, {
    iconLink: HouseIcon,
    onClick: onHomePageOpen
  }, "\u0414\u043E\u043C\u043E\u0439"), React.createElement(IconButton, {
    iconLink: MenuIcon,
    onClick: onGenderMenuRouteOpen
  }, "\u041C\u0435\u043D\u044E"))), React.createElement(EmailResultFooter, null, React.createElement(LogoImage, {
    src: MZLogo,
    alt: "Minzdrav"
  }), React.createElement(LogoImage, {
    src: FFLogo,
    alt: "OMS"
  }), React.createElement(LogoImage, {
    src: AlfaLogo,
    alt: "Alfa-strah"
  })));
};

export default EmailResult;