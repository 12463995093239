import styled, { css } from 'styled-components';
export var StyledBasicButtonComponent = function StyledBasicButtonComponent(Component) {
  return styled(Component).withConfig({
    displayName: "styles",
    componentId: "sc-1jgoynv-0"
  })(["display:flex;align-items:center;text-align:left;padding:0;"]);
};
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1jgoynv-1"
})(["", ";border-right:1px solid ", ";"], function (_ref) {
  var bigButton = _ref.bigButton;
  return css(["width:", ";min-width:", ";height:", ";padding:", ";"], bigButton ? '173px' : '100px', bigButton ? '173px' : '100px', bigButton ? '190px' : '100px', bigButton ? '50px' : '20px');
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.opacity_black;
});
export var LinkWrapper = styled.div.withConfig({
  displayName: "styles__LinkWrapper",
  componentId: "sc-1jgoynv-2"
})(["display:flex;align-items:center;", ";border-right:2px solid ", ";"], function (_ref3) {
  var bigButton = _ref3.bigButton;
  return css(["width:", ";min-width:", ";height:", ";padding:", ";"], bigButton ? '173px' : '128px', bigButton ? '173px' : '128px', bigButton ? '190px' : '128px', bigButton ? '50px' : '20px');
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.opacity_black;
});
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-1jgoynv-3"
})(["padding:10px 10px 10px 32px;font-size:2.3rem;line-height:2.5rem;"]);
export var Image = styled.div.withConfig({
  displayName: "styles__Image",
  componentId: "sc-1jgoynv-4"
})(["background-image:url(", ");background-repeat:no-repeat;background-position:center;background-size:contain;width:100%;height:100%;"], function (_ref5) {
  var src = _ref5.src;
  return src;
});