import React from 'react';
import { ArrowBack } from 'components/svg-icons';
import { BasicButton } from '../basic-button';
import { StyledBasicButtonComponent } from './styles';
var StyledBasicButton = StyledBasicButtonComponent(BasicButton);
export var BackButton = function BackButton(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick,
      className = _ref.className;
  return React.createElement(StyledBasicButton, {
    onClick: onClick,
    className: className
  }, React.createElement(ArrowBack, {
    rotate: false
  }), children);
};