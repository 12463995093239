import * as React from 'react';

var DatePicker = function DatePicker() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    "data-name": "Layer 1",
    viewBox: "0 0 512 512",
    x: "0px",
    y: "0px"
  }, React.createElement("path", {
    fill: "#ffffff",
    d: "M481.28,38H431v71.76c0,23.47-18,42.24-40.6,42.24H377.6C355,152,337,133.24,337,109.76V38H183v71.76c0,23.47-18.34,42.24-40.92,42.24h-12.8C106.7,152,88,133.24,88,109.76V38H30.72C13.82,38,0,52.75,0,70.31V480.07C0,497.63,13.82,512,30.72,512H481.28c16.9,0,30.72-14.37,30.72-31.93V70.31C512,52.75,498.18,38,481.28,38ZM469,445.5A22.57,22.57,0,0,1,446.5,468H65.5A22.57,22.57,0,0,1,43,445.5v-230A22.57,22.57,0,0,1,65.5,193h381A22.57,22.57,0,0,1,469,215.5v230Z"
  }), React.createElement("path", {
    fill: "#ffffff",
    d: "M129.28,134h12.8c12.7,0,22.92-11,22.92-24.24V23.95C165,10.74,154.78,0,142.08,0h-12.8C116.58,0,106,10.74,106,23.95v85.82C106,123,116.58,134,129.28,134Z"
  }), React.createElement("path", {
    fill: "#ffffff",
    d: "M377.6,134h12.8c12.7,0,22.6-11,22.6-24.24V23.94C413,10.74,403.1,0,390.4,0H377.6C364.9,0,355,10.74,355,23.94v85.82C355,123,364.9,134,377.6,134Z"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "80",
    y: "237",
    width: "90",
    height: "76"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "80",
    y: "348",
    width: "90",
    height: "76"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "211",
    y: "237",
    width: "90",
    height: "76"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "211",
    y: "348",
    width: "90",
    height: "76"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "345",
    y: "237",
    width: "90",
    height: "76"
  }), React.createElement("rect", {
    fill: "#ffffff",
    x: "345",
    y: "348",
    width: "90",
    height: "76"
  }));
};

export default DatePicker;