import Main from 'pages/main';
import Gender from 'pages/gender';
import GenderMenu from 'pages/gender-menu';
import Anatomy from 'pages/anatomy';
import Illnesses from 'pages/Illnesses-list';
import IllnessPage from 'pages/illness';
import LandingsPage from 'pages/landings';
import ZogPage from 'pages/zog';
import Quiz from 'pages/quiz';
import ChildrenGrowing from 'pages/children-growing';
import ChildrenGrowingLanding from 'pages/children-growing-landing';
import ZogCenterLanding from 'pages/zog-center-landing';
export var MainRoute = {
  url: '/',
  exact: true,
  rawUrl: function rawUrl() {
    return '/';
  },
  component: Main
};
export var GenderRoute = {
  url: '/gender-select/:ageId',
  rawUrl: function rawUrl(_ref) {
    var ageId = _ref.ageId;
    return "/gender-select/".concat(ageId);
  },
  component: Gender
};
export var GenderMenuRoute = {
  url: '/section-select/:ageId/:genderId',
  rawUrl: function rawUrl(_ref2) {
    var ageId = _ref2.ageId,
        genderId = _ref2.genderId;
    return "/section-select/".concat(ageId, "/").concat(genderId);
  },
  component: GenderMenu
};
export var IllnessRoute = {
  url: '/illness/:ageId/:genderId/:bodyPartId/:sicknessId',
  rawUrl: function rawUrl(_ref3) {
    var ageId = _ref3.ageId,
        genderId = _ref3.genderId,
        bodyPartId = _ref3.bodyPartId,
        sicknessId = _ref3.sicknessId;
    return "/illness/".concat(ageId, "/").concat(genderId, "/").concat(bodyPartId, "/").concat(sicknessId);
  },
  component: IllnessPage
};
export var LandingRoute = {
  url: '/landings/:ageId/:genderId/:landingId',
  rawUrl: function rawUrl(_ref4) {
    var ageId = _ref4.ageId,
        genderId = _ref4.genderId,
        landingId = _ref4.landingId;
    return "/landings/".concat(ageId, "/").concat(genderId, "/").concat(landingId);
  },
  component: LandingsPage
};
export var AnatomyRoute = {
  url: '/anatomy/:ageId/:genderId/',
  rawUrl: function rawUrl(_ref5) {
    var ageId = _ref5.ageId,
        genderId = _ref5.genderId;
    return "/anatomy/".concat(ageId, "/").concat(genderId);
  },
  component: Anatomy
};
export var IlnessesListRoute = {
  url: '/illnesses/:ageId/:genderId/:bodyPartId',
  rawUrl: function rawUrl(_ref6) {
    var ageId = _ref6.ageId,
        genderId = _ref6.genderId,
        bodyPartId = _ref6.bodyPartId;
    return "/illnesses/".concat(ageId, "/").concat(genderId, "/").concat(bodyPartId);
  },
  component: Illnesses
};
export var ZogRoute = {
  url: '/zog/:ageId/:genderId/',
  rawUrl: function rawUrl(_ref7) {
    var ageId = _ref7.ageId,
        genderId = _ref7.genderId;
    return "/zog/".concat(ageId, "/").concat(genderId);
  },
  component: ZogPage
};
export var ZogLandingRoute = {
  url: '/zog-landing/:ageId/:genderId/:zogItemId',
  rawUrl: function rawUrl(_ref8) {
    var ageId = _ref8.ageId,
        genderId = _ref8.genderId,
        zogItemId = _ref8.zogItemId;
    return "/zog-landing/".concat(ageId, "/").concat(genderId, "/").concat(zogItemId);
  },
  component: ZogCenterLanding
};
export var ZogCenterLandingRoute = {
  url: '/zog-landing/center/:ageId/:genderId',
  rawUrl: function rawUrl(_ref9) {
    var ageId = _ref9.ageId,
        genderId = _ref9.genderId;
    return "/zog-landing/center/".concat(ageId, "/").concat(genderId);
  },
  component: ZogCenterLanding
};
export var TestRoute = {
  url: '/test/:ageId/:genderId/',
  rawUrl: function rawUrl(_ref10) {
    var ageId = _ref10.ageId,
        genderId = _ref10.genderId;
    return "/test/".concat(ageId, "/").concat(genderId);
  },
  component: Quiz
};
export var ChildrenGrowingRoute = {
  url: '/children-growing/:ageId/:genderId',
  rawUrl: function rawUrl(_ref11) {
    var ageId = _ref11.ageId,
        genderId = _ref11.genderId;
    return "/children-growing/".concat(ageId, "/").concat(genderId);
  },
  component: ChildrenGrowing
};
export var ChildrenGrowingLandingRoute = {
  url: '/children-growing-landing/:ageId/:genderId/:bodyPartId',
  rawUrl: function rawUrl(_ref12) {
    var ageId = _ref12.ageId,
        genderId = _ref12.genderId,
        bodyPartId = _ref12.bodyPartId;
    return "/children-growing-landing/".concat(ageId, "/").concat(genderId, "/").concat(bodyPartId);
  },
  component: ChildrenGrowingLanding
};
export var routeList = [MainRoute, GenderRoute, GenderMenuRoute, IllnessRoute, AnatomyRoute, IlnessesListRoute, LandingRoute, ZogRoute, ZogCenterLandingRoute, ZogLandingRoute, TestRoute, ChildrenGrowingRoute, ChildrenGrowingLandingRoute];