import styled, { css } from 'styled-components';
import { BasicButton } from 'components/button';
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-5mozlx-0"
})(["font-size:24px;line-height:34px;padding:95px 40px;white-space:pre-line;"]);
export var ButtonContainer = styled.div.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-5mozlx-1"
})(["display:flex;align-items:center;justify-content:center;"]);
export var ModalContent = styled.div.withConfig({
  displayName: "styles__ModalContent",
  componentId: "sc-5mozlx-2"
})(["background-color:", ";box-shadow:20px 0px 103px -9px ", ";color:", ";padding:35px 30px;margin-top:303px;margin-left:75px;margin-right:45px;width:575px;position:relative;pointer-events:all;z-index:-1;&:before{content:'';border-radius:50%;position:absolute;top:59px;left:-5px;display:block;height:12px;width:12px;z-index:-1;", ";}&:after{content:'';background:", ";border-left:4px solid #00000010;position:absolute;top:47px;left:0;display:block;height:36px;width:18px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.modalBg;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.opacity_blue;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
}, function (_ref4) {
  var theme = _ref4.theme;
  return css(["border:1px solid ", ";box-shadow:0px 0px 4px 8px rgba(67,113,165,0.5);background:", ";background:-moz-linear-gradient( top,", " 0%,", " 50%,", " 100% );background:-webkit-linear-gradient( top,", " 0%,", " 50%,", " 100% );background:linear-gradient( to bottom,", " 0%,", " 50%,", " 100% );"], theme.colors.blue, theme.colors.blue600, theme.colors.blue600, theme.colors.blue700, theme.colors.blue900, theme.colors.blue600, theme.colors.blue700, theme.colors.blue900, theme.colors.blue600, theme.colors.blue700, theme.colors.blue900);
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.modalBg;
});
export var TitleContainer = styled.div.withConfig({
  displayName: "styles__TitleContainer",
  componentId: "sc-5mozlx-3"
})(["display:flex;align-items:center;justify-content:center;font-size:25px;padding-bottom:27px;min-height:84px;border-bottom:1px solid ", ";span{padding-left:20px;}"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.darkIndigo600;
});
export var TitleIcon = styled.div.withConfig({
  displayName: "styles__TitleIcon",
  componentId: "sc-5mozlx-4"
})(["min-width:56px;width:56px;height:56px;position:relative;border-radius:50%;border:1px solid ", ";background-color:", ";background-image:url(\"", "\");background-repeat:no-repeat;background-position:center;background-size:70%;&:before{content:'';background-color:", ";border-radius:50%;display:block;position:absolute;top:-9px;left:-9px;width:72px;height:72px;z-index:-1;}"], function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.blue;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.mainDarkBlue;
}, function (_ref9) {
  var src = _ref9.src;
  return src;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.mainDarkBlue;
});
export var BackButton = styled.div.withConfig({
  displayName: "styles__BackButton",
  componentId: "sc-5mozlx-5"
})(["display:flex;align-items:center;justify-content:center;width:143px;height:58px;font-size:20px;margin:20px 0;border:1px solid ", ";border-radius:8px;color:", ";transition:0.3s ease-in-out color;cursor:pointer;&:hover{color:", ";}svg{width:12px;height:12px;fill:", ";margin-right:7px;}"], function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.gray300;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.gray300;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.white;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.gray300;
});
export var Button = styled(BasicButton).withConfig({
  displayName: "styles__Button",
  componentId: "sc-5mozlx-6"
})(["background-color:transparent;border:1px solid ", ";height:58px;font-size:20px;margin:20px 0;padding:0 30px;"], function (_ref15) {
  var theme = _ref15.theme;
  return theme.colors.white;
});
export var BodyMapContainer = styled.div.withConfig({
  displayName: "styles__BodyMapContainer",
  componentId: "sc-5mozlx-7"
})(["position:fixed;width:600px;right:22%;pointer-events:none;& > div{pointer-events:none;}"]);
export var ModalContentWrapper = styled.div.withConfig({
  displayName: "styles__ModalContentWrapper",
  componentId: "sc-5mozlx-8"
})(["", ";"], function (_ref16) {
  var theme = _ref16.theme;
  return css(["&:before{content:'';display:block;border-radius:0 0 30px 60px;background-color:transparent;width:50px;height:45px;position:absolute;right:-40px;top:0;box-shadow:inset 21px -10px 0px 0 ", ";}&:after{content:'';display:block;border-radius:0 0 8px 0;background-color:", ";width:42px;height:calc(100% - 35px);position:absolute;right:-40px;bottom:0;}"], theme.colors.modalBg, theme.colors.modalBg);
});
export var Close = styled.div.withConfig({
  displayName: "styles__Close",
  componentId: "sc-5mozlx-9"
})(["display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;position:absolute;top:-30px;right:-70px;padding:15px;border-radius:50%;overflow:hidden;cursor:pointer;pointer-events:all;svg{width:30px;height:30px;fill:", ";}"], function (_ref17) {
  var theme = _ref17.theme;
  return theme.colors.white;
});