import styled, { css } from 'styled-components';
import ArrowLess from 'components/svg-icons/arrow-less.svg';
var ArrowBefore = css(["content:url(\"", "\");border-radius:50%;border:1px solid ", ";display:block;color:", ";padding-right:5px;padding-top:2px;width:50px;height:50px;opacity:1;"], ArrowLess, function (_ref) {
  var theme = _ref.theme;
  return theme.colors.blue;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
});
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1joq5fq-0"
})(["background-color:", ";width:100%;overflow:hidden;position:absolute;top:0;z-index:1000;.slick-prev{left:30px;width:50px;height:50px;z-index:1;&:before{", ";}}.slick-next{right:30px;width:50px;height:50px;&:before{", ";transform:rotate(180deg);}}.slick-list{margin:0 80px;}.slick-track{display:flex;align-items:center;}"], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.mainDarkBlue;
}, ArrowBefore, ArrowBefore);
export var ExtraListItem = styled.div.withConfig({
  displayName: "styles__ExtraListItem",
  componentId: "sc-1joq5fq-1"
})(["font-size:24px;line-height:1;text-transform:uppercase;text-align:center;font-weight:bold;padding-top:30px;padding-bottom:30px;color:", ";.slick-center &{color:", ";}cursor:pointer;transition:color 0.3s;"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.blue;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.white;
});
export var SliderArrow = styled.div.withConfig({
  displayName: "styles__SliderArrow",
  componentId: "sc-1joq5fq-2"
})(["cursor:pointer;"]);