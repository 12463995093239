function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useEffect, useRef } from 'react';
import { getImageLink } from 'src/utils';
import { BasicModal } from 'components/modal';
import BodyMap from 'components/body-map';
import { Line, CloseIcon } from 'components/svg-icons';
import { Text, ButtonContainer, TitleContainer, Button, ModalContent, TitleIcon, BodyMapContainer, Close, ModalContentWrapper } from './styles';
var initialBodyPart = {
  id: 0,
  growthButtonCaption: '',
  diseaseButtonCaption: '',
  growthDescription: '',
  diseaseDescription: '',
  icon: '',
  actual: 0,
  caption: '',
  genderId: 0,
  mainMenuId: 0,
  posx: 0,
  posy: 0,
  organMapImage: '',
  visibleDot: false
};

var OrganHint = function OrganHint(props) {
  var _props$bodyParts = props.bodyParts,
      bodyParts = _props$bodyParts === void 0 ? [initialBodyPart] : _props$bodyParts,
      currentBodyPartId = props.currentBodyPartId,
      isOpen = props.isOpen,
      onOpen = props.onOpen,
      onClose = props.onClose,
      bodyPartCoordinat = props.bodyPartCoordinat,
      _props$openPageText = props.openPageText,
      openPageText = _props$openPageText === void 0 ? 'Узнать о рисках для здоровья' : _props$openPageText,
      getOrganDescription = props.getOrganDescription,
      getOrganName = props.getOrganName;

  var _useState = useState({
    pageX: 0,
    pageY: 0
  }),
      _useState2 = _slicedToArray(_useState, 2),
      modalCoordinate = _useState2[0],
      setModalCoordinate = _useState2[1];

  var _useState3 = useState(initialBodyPart),
      _useState4 = _slicedToArray(_useState3, 2),
      currentBodyPart = _useState4[0],
      setCurrentBodyPart = _useState4[1];

  var caption = currentBodyPart.caption,
      icon = currentBodyPart.icon;

  var customToggleModal = function customToggleModal() {
    onClose();
  };

  var handleOnOpen = function handleOnOpen() {
    onOpen(currentBodyPart.id);
  };

  var modalRef = useRef();
  useEffect(function () {
    if (currentBodyPartId) {
      var bodyPart = bodyParts.find(function (item) {
        return item.id === currentBodyPartId;
      });
      setCurrentBodyPart(bodyPart);
    }
  }, [bodyParts, currentBodyPartId]);
  useEffect(function () {
    if (modalRef) {
      var modalOffset = modalRef.current ? modalRef.current.getBoundingClientRect() : {
        x: 0,
        y: 0
      };
      setModalCoordinate({
        pageX: modalOffset.x - 4,
        pageY: modalOffset.y + 65
      });
    }
  }, [modalRef, isOpen]);

  var renderFeaturesSelectionHintModal = function renderFeaturesSelectionHintModal() {
    return React.createElement(BasicModal, {
      title: "",
      isOpen: isOpen,
      onClose: customToggleModal,
      showClose: true,
      isRight: true,
      noContentContainer: true,
      noOverlay: true,
      noFilter: true,
      isFullWidth: true
    }, React.createElement(BodyMapContainer, null, React.createElement(BodyMap, {
      dotPosition: {
        posx: bodyPartCoordinat.pageX,
        posy: bodyPartCoordinat.pageY
      },
      bodyParts: bodyParts,
      currentBodyPartId: currentBodyPartId,
      showOrganMap: true
    })), React.createElement(Line, {
      x1: bodyPartCoordinat.pageX,
      y1: bodyPartCoordinat.pageY,
      x2: modalCoordinate.pageX,
      y2: modalCoordinate.pageY
    }), React.createElement(ModalContent, {
      ref: modalRef
    }, React.createElement(ModalContentWrapper, null, React.createElement(TitleContainer, null, icon && React.createElement(TitleIcon, {
      src: getImageLink(icon)
    }), React.createElement("span", null, getOrganName(currentBodyPart) || caption)), React.createElement(Close, {
      role: "button",
      tabIndex: -1,
      onClick: customToggleModal
    }, React.createElement(CloseIcon, null)), React.createElement(Text, null, getOrganDescription(currentBodyPart)), React.createElement(ButtonContainer, null, React.createElement(Button, {
      onClick: handleOnOpen
    }, openPageText)))));
  };

  return React.createElement(React.Fragment, null, renderFeaturesSelectionHintModal());
};

export default OrganHint;