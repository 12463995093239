import { createContext } from 'react';
export var AppContext = createContext({
  appContext: {
    ageId: null,
    genderId: null
  },
  ageMenu: null,
  zogMenu: null,
  bodyState: null,
  extraMenu: null,
  extraMenuList: null,
  clearBodyState: null,
  setState: function setState() {
    return null;
  },
  setIsLoadedZogMenu: function setIsLoadedZogMenu() {
    return null;
  },
  clearExtraMenuList: function clearExtraMenuList() {
    return null;
  },
  clearExtraMenu: function clearExtraMenu() {
    return null;
  },
  isLoadingZogMenu: null,
  hintShowing: {
    hint: true,
    zog: true,
    features: true
  },
  setIsHintShowing: function setIsHintShowing() {
    return null;
  },
  dropIsHintShowing: function dropIsHintShowing() {
    return null;
  },
  getExtraMenuIndex: function getExtraMenuIndex() {
    return null;
  }
});
export default AppContext;