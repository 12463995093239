import React from 'react';
import { PaginationConainer, PaginationItem } from './styles';

var Pagination = function Pagination(props) {
  var _props$items = props.items,
      items = _props$items === void 0 ? [] : _props$items,
      activeItem = props.activeItem;
  return React.createElement(PaginationConainer, null, items.length > 0 && items.map(function (item, index) {
    return React.createElement(PaginationItem, {
      key: "questioon-".concat(item.id),
      active: index === activeItem,
      "data-id": item.id
    }, index + 1);
  }));
};

export default Pagination;