import React from 'react';
import { Wrapper, StyledBasicButtonComponent, Text, Image, LinkWrapper } from './styles';
import { BasicButton } from '../basic-button';
var StyledBasicButton = StyledBasicButtonComponent(BasicButton);
export var MenuButton = function MenuButton(_ref) {
  var id = _ref.id,
      iconLink = _ref.iconLink,
      children = _ref.children,
      onClick = _ref.onClick,
      iconComponent = _ref.iconComponent,
      bigButton = _ref.bigButton;
  return React.createElement(StyledBasicButton, {
    id: id,
    bigButton: bigButton,
    onClick: onClick
  }, iconComponent && React.createElement(Wrapper, {
    bigButton: bigButton
  }, iconComponent), iconLink && React.createElement(LinkWrapper, {
    bigButton: bigButton
  }, React.createElement(Image, {
    src: iconLink
  })), React.createElement(Text, null, children));
};