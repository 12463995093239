export var answers = [{
  id: 1,
  title: 'answer1'
}, {
  id: 2,
  title: 'answer2'
}, {
  id: 3,
  title: 'answer3'
}, {
  id: 4,
  title: 'answer4'
}];