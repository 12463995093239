import styled from 'styled-components';
export var Button = styled.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-1iv0hoc-0"
})(["display:flex;align-items:center;text-align:left;padding:0 30px;height:85px;border-radius:8px;border:2px solid ", ";background:", ";font-size:1.5rem;color:", ";transition:box-shadow,0.3s ease-in-out;cursor:pointer;&:hover{box-shadow:0px 0px 10px 0px ", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.blue;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.mainDarkBlue;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.opacity_blue;
});
export var LinkWrapper = styled.div.withConfig({
  displayName: "styles__LinkWrapper",
  componentId: "sc-1iv0hoc-1"
})(["display:flex;align-items:center;justify-content:center;width:35px;height:35px;margin-right:25px;"]);
export var Image = styled.div.withConfig({
  displayName: "styles__Image",
  componentId: "sc-1iv0hoc-2"
})(["background-image:url(", ");background-repeat:no-repeat;background-position:center;background-size:contain;width:100%;height:100%;"], function (_ref5) {
  var src = _ref5.src;
  return src;
});