import React from 'react';
import { IconButton } from '../button';
import { HintIconContainer } from './styles';
import HintIconSrc from 'src/assets/icons/hint-new.png';

var HintButton = function HintButton(_ref) {
  var handleClick = _ref.handleClick;
  return React.createElement(HintIconContainer, null, React.createElement(IconButton, {
    iconLink: HintIconSrc,
    onClick: handleClick
  }, "\u041F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0430"));
};

export default HintButton;