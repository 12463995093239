function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useContext } from 'react';
import { AppContext } from 'src/services/context.service';
import { Environment } from 'src/services';
import { MainRoute, GenderMenuRoute } from 'src/utils/const';
import GenderImage from 'components/gender-image';
import { GENDER_TYPES, genderData } from './const';
import { Container, LeftSide, RightSide, BackButtonWrapper } from './styles';
import { BackButton } from '../button';
var baseImgUrl = Environment.BaseImageURL;

var GenderSelection = function GenderSelection(_ref) {
  var history = _ref.history,
      ageId = _ref.ageId;

  var _useContext = useContext(AppContext),
      ageMenu = _useContext.ageMenu,
      dropIsHintShowing = _useContext.dropIsHintShowing;

  var _ageMenu$filter = ageMenu.filter(function (menuItem) {
    return menuItem.id === ageId;
  }),
      _ageMenu$filter2 = _slicedToArray(_ageMenu$filter, 1),
      currentMenu = _ageMenu$filter2[0];

  if (ageMenu.length > 0 && !currentMenu) {
    history.push(MainRoute.rawUrl());
  }

  var handleGenderClick = function handleGenderClick(ev) {
    var genderId = Number(ev.currentTarget.dataset.gender);
    dropIsHintShowing();
    history.push(GenderMenuRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var handleBackClick = function handleBackClick() {
    history.push(MainRoute.rawUrl());
  };

  return React.createElement(Container, null, currentMenu && React.createElement(React.Fragment, null, React.createElement(LeftSide, {
    "data-gender": GENDER_TYPES.male,
    onClick: handleGenderClick
  }, React.createElement(GenderImage, {
    genderId: GENDER_TYPES.male,
    imgSrc: "".concat(baseImgUrl).concat(currentMenu.mainMenuMale),
    title: genderData[GENDER_TYPES.male][currentMenu.id]
  })), React.createElement(RightSide, {
    "data-gender": GENDER_TYPES.female,
    onClick: handleGenderClick
  }, React.createElement(GenderImage, {
    genderId: GENDER_TYPES.female,
    imgSrc: "".concat(baseImgUrl).concat(currentMenu.mainMenuFemale),
    title: genderData[GENDER_TYPES.female][currentMenu.id]
  }))), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434")));
};

export default GenderSelection;