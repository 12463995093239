function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useState } from 'react';
import { Environment } from 'src/services';
import { ZogLandingRoute, ZogCenterLandingRoute } from 'src/utils/const';
import { Container, Wrapper, ZozhItemWrapper, ZozhItem, Image, CenterBlock, DatePickerIcon, CenterBlockTitle, Text } from './styles';
var baseImgUrl = Environment.BaseImageURL;

var ZogSelector = function ZogSelector(_ref) {
  var zogMenu = _ref.zogMenu,
      history = _ref.history,
      genderId = _ref.genderId,
      ageId = _ref.ageId;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      preparedZogMenu = _useState2[0],
      setPreparedZogMenu = _useState2[1];

  var handleZogLandingOpen = function handleZogLandingOpen(zogItem) {
    return function () {
      if (zogItem) {
        history.push(ZogLandingRoute.rawUrl({
          ageId: ageId,
          genderId: genderId,
          zogItemId: zogItem.id
        }));
      }
    };
  };

  var handleZogCenterLandingOpen = function handleZogCenterLandingOpen() {
    history.push(ZogCenterLandingRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  useEffect(function () {
    if (zogMenu.length > 0 && preparedZogMenu.length === 0) {
      var prepared = zogMenu.filter(function (item) {
        return !item.center && !item.footer;
      }).slice(0, 7).sort(function (a, b) {
        return a.posId - b.posId;
      });
      setPreparedZogMenu(prepared);
    }
  }, [preparedZogMenu.length, zogMenu]);
  return React.createElement(Wrapper, {
    id: "age-image-".concat(ageId)
  }, React.createElement(Container, null, React.createElement(ZozhItemWrapper, null, preparedZogMenu.map(function (zog) {
    return React.createElement(ZozhItem, {
      key: "age-item-".concat(zog.id),
      "data-id": zog.id,
      onClick: handleZogLandingOpen(zog)
    }, React.createElement(Image, {
      src: "".concat(baseImgUrl).concat(zog.icon),
      zoom: zog.zoom,
      posX: zog.posX,
      posY: zog.posY
    }));
  })), React.createElement(CenterBlock, {
    onClick: handleZogCenterLandingOpen
  }, React.createElement(DatePickerIcon, null), React.createElement(CenterBlockTitle, null, "\u042F \u0432\u044B\u0431\u0438\u0440\u0430\u044E \u0437\u0434\u043E\u0440\u043E\u0432\u044C\u0435!"))), React.createElement("div", null, preparedZogMenu.map(function (zog) {
    return React.createElement(Text, {
      key: "age-item-".concat(zog.id),
      "data-id": zog.id,
      onClick: handleZogLandingOpen(zog)
    }, zog.name);
  })));
};

export default ZogSelector;