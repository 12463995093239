function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import { UI } from 'src/services';
import { CloseIcon } from '../svg-icons';
import { Overlay, Modal, Close, Title, ModalContent, Container } from './styles';
import { BackButton } from '../button';
export function BasicModal(props) {
  var isOpen = props.isOpen,
      clickClose = props.clickClose,
      escClose = props.escClose,
      onClose = props.onClose,
      styles = props.styles,
      title = props.title,
      showClose = props.showClose,
      children = props.children,
      showBackButton = props.showBackButton,
      isAnimated = props.isAnimated,
      _props$noContentConta = props.noContentContainer,
      noContentContainer = _props$noContentConta === void 0 ? false : _props$noContentConta,
      noOverlay = props.noOverlay,
      noFilter = props.noFilter,
      modalHeight = props.modalHeight,
      isRight = props.isRight,
      isLeft = props.isLeft,
      isFullWidth = props.isFullWidth,
      isFullHeightBlock = props.isFullHeightBlock;
  var bodyRef = document.getElementsByTagName('BODY')[0];
  var appRef = document.getElementById('app');
  var modalRef = useRef(null);
  var overlayRef = useRef(null);
  var rootNode = UI.createModalRootNode();
  var transition = useTransition(isOpen, null, {
    enter: {
      transform: 'translate3d(0, 0, 0) scale(1)'
    },
    from: {
      opacity: 0.95,
      transform: 'translate3d(0, 0, 0) scale(0)'
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 0, 0) scale(0.3)'
    }
  });
  var handleClickOutside = useCallback(function (event) {
    if (event.target instanceof HTMLElement && modalRef.current && !modalRef.current.contains(event.target) && noOverlay) {
      onClose();
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [modalRef.current]);

  var handleEscKeyPress = function handleEscKeyPress(event) {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  var handleClose = useCallback(function () {
    onClose();
  }, [onClose]);

  var handleOverlayClick = function handleOverlayClick(event) {
    if (overlayRef.current && overlayRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(function () {
    if (modalRef && modalRef.current !== null && isOpen) {
      modalRef.current.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      }, {
        passive: false
      });
    }

    return function () {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEscKeyPress);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef, isOpen]);
  useEffect(function () {
    if (clickClose && isOpen) {
      document.addEventListener('click', handleClickOutside);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [clickClose, isOpen]);
  useEffect(function () {
    if (escClose && isOpen) {
      document.addEventListener('keydown', handleEscKeyPress);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [escClose, isOpen]);
  useEffect(function () {
    return function () {
      appRef.classList.remove('isFiltered');
      bodyRef.classList.remove('isHidden');
      return function () {
        rootNode.parentNode.removeChild(rootNode);
      };
    };
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  var AnimatedModal = animated(Modal);

  var renderModal = function renderModal() {
    var render = null;

    if (isAnimated) {
      render = transition.map(function (_ref) {
        var item = _ref.item,
            springProps = _ref.props,
            key = _ref.key,
            state = _ref.state;

        if (state === 'enter') {
          item ? bodyRef.classList.add('isHidden') : bodyRef.classList.remove('isHidden');
          item && !noFilter ? appRef.classList.add('isFiltered') : appRef.classList.remove('isFiltered');
        }

        return item && React.createElement(Container, {
          key: key,
          isLeft: isLeft,
          isRight: isRight,
          hidden: noOverlay
        }, React.createElement(Overlay, {
          ref: overlayRef,
          onClick: handleOverlayClick,
          hidden: noOverlay
        }), React.createElement(AnimatedModal, {
          key: key,
          style: _objectSpread({}, springProps, {}, styles),
          ref: modalRef,
          isLeft: isLeft,
          isRight: isRight,
          isFullWidth: isFullWidth,
          isFullHeightBlock: isFullHeightBlock,
          showClose: showClose
        }, title && React.createElement(Title, null, React.createElement("span", null, title)), showClose && React.createElement(Close, {
          role: "button",
          shiftTop: !!title,
          tabIndex: -1,
          onClick: handleClose,
          isFullWidth: isFullWidth
        }, React.createElement(CloseIcon, null)), !noContentContainer && React.createElement(ModalContent, {
          modalHeight: modalHeight,
          showClose: showClose,
          isLeft: isLeft,
          isFullWidth: isFullWidth
        }, children), noContentContainer && children, showBackButton && React.createElement(BackButton, {
          className: "modal-back-button",
          onClick: handleClose
        }, "\u041D\u0430\u0437\u0430\u0434")));
      });
    }

    if (isOpen) {
      render = React.createElement(Container, {
        isLeft: isLeft,
        isRight: isRight,
        hidden: noOverlay
      }, React.createElement(Overlay, {
        ref: overlayRef,
        onClick: handleOverlayClick,
        hidden: noOverlay
      }), React.createElement(Modal, {
        ref: modalRef,
        style: styles,
        isLeft: isLeft,
        isRight: isRight,
        isFullWidth: isFullWidth,
        isFullHeightBlock: isFullHeightBlock,
        showClose: showClose
      }, title && React.createElement(Title, null, React.createElement("span", null, title)), !noContentContainer && React.createElement(ModalContent, {
        modalHeight: modalHeight,
        isLeft: isLeft,
        showClose: showClose,
        isFullWidth: isFullWidth
      }, showClose && React.createElement(Close, {
        role: "button",
        tabIndex: -1,
        onClick: handleClose,
        isFullWidth: isFullWidth
      }, React.createElement(CloseIcon, null)), children), noContentContainer && children, showBackButton && React.createElement(BackButton, {
        className: "modal-back-button",
        onClick: handleClose
      }, "\u041D\u0430\u0437\u0430\u0434")));
    }

    return render;
  };

  return ReactDOM.createPortal(React.createElement(React.Fragment, null, renderModal()), rootNode);
}