import styled from 'styled-components';
export var Button = styled.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-19hhvio-0"
})(["color:", ";padding:10px;background-color:", ";border-radius:8px;border:none;transition:box-shadow,0.3s ease-in-out;cursor:pointer;&:hover{box-shadow:0px 0px 10px 0px ", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.blue;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.opacity_blue;
});