import React from 'react';
import { ArrowBack } from 'components/svg-icons';
import { BasicButton } from '../basic-button';
import { StyledBasicButtonComponent } from './styles';
var StyledBasicButton = StyledBasicButtonComponent(BasicButton);
export var NextButton = function NextButton(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick,
      className = _ref.className;
  return React.createElement(StyledBasicButton, {
    onClick: onClick,
    className: className
  }, children, React.createElement(ArrowBack, {
    rotate: true
  }));
};