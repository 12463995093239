import styled, { css } from 'styled-components';
import Male from './male.svg';
import Female from './female.svg';
export var Title = styled.span.withConfig({
  displayName: "styles__Title",
  componentId: "f3ge1g-0"
})(["position:absolute;bottom:293px;left:50%;font-size:50px;text-align:center;font-weight:bold;color:", ";transition:bottom 0.3s;transform:translateX(-50%);white-space:pre-line;&:before{content:url(", ");height:50px;width:50px;display:block;position:absolute;top:-57px;left:50%;transform:translateX(-50%);}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var genderId = _ref2.genderId;
  return genderId === 1 ? Male : Female;
});
export var Image = styled.div.withConfig({
  displayName: "styles__Image",
  componentId: "f3ge1g-1"
})(["height:100%;background-repeat:no-repeat;background-position:center;background-size:cover;", ";transition:transform 0.3s;transform:scale(1);will-change:transform;&:after{content:'';display:block;position:absolute;bottom:0;width:100%;height:70%;opacity:1;transition:opacity 0.3s;background:rgba(0,0,0,0);background:-moz-linear-gradient( top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 45%,rgba(0,0,0,0.9) 100% );background:-webkit-gradient( left top,left bottom,color-stop(0%,rgba(0,0,0,0)),color-stop(45%,rgba(0,0,0,0.15)),color-stop(100%,rgba(0,0,0,0.9)) );background:-webkit-linear-gradient( top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 45%,rgba(0,0,0,0.9) 100% );background:-o-linear-gradient( top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 45%,rgba(0,0,0,0.9) 100% );background:-ms-linear-gradient( top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 45%,rgba(0,0,0,0.9) 100% );background:linear-gradient( to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 45%,rgba(0,0,0,0.9) 100% );filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000',endColorstr='#000000',GradientType=0 );}"], function (_ref3) {
  var imgSrc = _ref3.imgSrc;
  return css(["background-image:url(", ");"], imgSrc);
});
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "f3ge1g-2"
})(["border:1px solid ", ";height:100%;min-height:100vh;width:100%;overflow:hidden;position:relative;box-shadow:inset 0px -26px 30px 0px rgba(0,0,0,0.25);cursor:pointer;&:hover{", "{&:after{opacity:0.8;}}}"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.borderBlue;
}, Image);