import styled from 'styled-components';
export var SubTitle = styled.div.withConfig({
  displayName: "styles__SubTitle",
  componentId: "sc-1ferogh-0"
})(["font-size:34px;color:", ";text-shadow:0px 0px 64px rgba(60,170,255,0.8);margin-left:15.4%;padding-bottom:5%;&:before{content:'';display:block;width:100px;height:4px;margin:15px 0 10px 0;background-color:", ";border-radius:4px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.blue;
});