export var LAYOUT = {
  default: ['q w e r t y u i o p', 'a s d f g h j k l', '{shift} z x c v b n m {bksp}', '{alt} @ . {space} .ru'],
  shift: ['Q W E R T Y U I O P', 'A S D F G H J K L', '{shiftactivated} Z X C V B N M {bksp}', '{alt} @ . {space} .ru'],
  alt: ['1 2 3 4 5 6 7 8 9 0', "@ # $ & * ( ) ' \" _", '{shift} . - + = / ; : ! ?', '{default} {space} {bksp}']
};
export var LAYOUT_RUSSIAN = {
  default: ['ё 1 2 3 4 5 6 7 8 9 0 - = {bksp}', '{tab} й ц у к е н г ш щ з х ъ \\', '{lock} ф ы в а п р о л д ж э {enter}', '{shift} \\ я ч с м и т ь б ю / {shift}', '.com @ {space}'],
  shift: ['Ё ! " № ; % : ? * ( ) _ + {bksp}', '{tab} Й Ц У К Е Н Г Ш Щ З Х Ъ /', '{lock} Ф Ы В А П Р О Л Д Ж Э {enter}', '{shift} / Я Ч С М И Т Ь Б Ю / {shift}', '.com @ {space}'],
  alt: ['1 2 3 4 5 6 7 8 9 0', "@ # $ & * ( ) ' \" _", '{shift} . - + = / ; : ! ?', '{default} {space} {bksp}']
};
export var DISPLAY = {
  '{alt}': '123',
  '{shift}': ' ',
  '{shiftactivated}': " ",
  '{bksp}': ' ',
  '{space}': ' ',
  '{default}': 'ABC'
};
export var CUSTOM = [{
  class: 'custom-button',
  buttons: '{shift} {shiftactivated} {alt} {bksp} {default}'
}, {
  class: 'custom-space-button',
  buttons: '{space}'
}, {
  class: 'custom-arrow-button',
  buttons: '{shift} {shiftactivated} {bksp}'
}, {
  class: 'custom-bksp-button',
  buttons: '{bksp}'
}, {
  class: 'custom-shiftactivated-button',
  buttons: '{shiftactivated}'
}, {
  class: 'custom-alt-button',
  buttons: '{alt} {default}'
}];

var validateEmail = function validateEmail(email) {
  var regexText = "^(?:[a-z0-9!#\$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#\$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$";
  return new RegExp(regexText).test(email);
};

export var validate = function validate(value) {
  if (!value) {
    return 'Это поле обязательное для заполнения';
  }

  if (validateEmail(value) === false) {
    return 'Обязательные символы для E-mail "@" и "."';
  }

  return '';
};