// easing
var easeInOutQuad = function easeInOutQuad(t, b, c, d) {
  // eslint-disable-next-line
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b; // eslint-disable-next-line

  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

export function scrollTo(element, to) {
  var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  var start = element.scrollTop;
  var change = to - start;
  var increment = 20;
  var currentTime = 0;

  var animateScroll = function animateScroll() {
    currentTime += increment;
    var y = easeInOutQuad(currentTime, start, change, duration); // eslint-disable-next-line no-param-reassign

    element.scrollTop = y;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
}