function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from 'src/services/context.service';
import { MainRoute, GenderMenuRoute } from 'src/utils/const';
import ExtraMenu from 'components/extra-menu';
import { ExtraMenuTypes } from 'components/extra-menu/interface';
import ZogSelector from 'components/zog-selection';
import ZogHint from 'components/zog-hint';
import { IconButton, BackButton } from 'components/button';
import HouseIcon from 'src/assets/icons/house.png';
import MenuIcon from 'src/assets/icons/menu.svg';
import { BackButtonWrapper } from './styles';
export default function Zog(props) {
  var history = props.history,
      params = props.match.params;
  var genderId = Number(params.genderId);
  var ageId = Number(params.ageId);

  var _useContext = useContext(AppContext),
      zogMenu = _useContext.zogMenu,
      setState = _useContext.setState,
      appContext = _useContext.appContext,
      extraMenuList = _useContext.extraMenuList,
      getExtraMenuIndex = _useContext.getExtraMenuIndex;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      initialSlide = _useState2[0],
      setInitialSlide = _useState2[1];

  var handleBackClick = function handleBackClick(event) {
    event.preventDefault();
    history.goBack();
  };

  var handleGenderMenuOpen = function handleGenderMenuOpen() {
    history.push(GenderMenuRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var handleHomePageOpen = function handleHomePageOpen() {
    history.push(MainRoute.rawUrl());
  };

  useEffect(function () {
    if (ageId && genderId) {
      if (appContext.ageId !== ageId && appContext.genderId !== genderId) {
        setState({
          ageId: ageId,
          genderId: genderId
        });
      }
    }
  }, [ageId, appContext.ageId, appContext.genderId, genderId, setState]);
  useEffect(function () {
    if (extraMenuList.length > 0) {
      var extraIndex = getExtraMenuIndex(ExtraMenuTypes.ZOG);

      if (extraIndex !== -1) {
        setInitialSlide(extraIndex);
      }
    }
  }, [extraMenuList.length, getExtraMenuIndex]);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0417\u041E\u0416")), React.createElement(ExtraMenu, {
    items: extraMenuList,
    initialSlide: initialSlide,
    history: history
  }), React.createElement(ZogSelector, {
    zogMenu: zogMenu,
    history: history,
    ageId: Number(ageId),
    genderId: Number(genderId)
  }), React.createElement(ZogHint, {
    history: history,
    genderId: genderId,
    ageId: ageId,
    zogMenu: zogMenu
  }), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434"), React.createElement(IconButton, {
    iconLink: HouseIcon,
    onClick: handleHomePageOpen
  }, "\u0414\u043E\u043C\u043E\u0439"), React.createElement(IconButton, {
    iconLink: MenuIcon,
    onClick: handleGenderMenuOpen
  }, "\u041C\u0435\u043D\u044E")));
}