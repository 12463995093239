function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React, { useCallback, useEffect, useRef } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { Router } from 'react-router';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { routeList } from 'src/utils/const';
import { Environment } from 'src/services';
import Theme from 'src/styles/theme';
import Normalize from 'src/styles/normalize';
import { globalStyle } from 'src/styles/main';
var GlobalStyle = createGlobalStyle(_templateObject(), Normalize, globalStyle);
var isElectron = Environment.isElectron;
export function Routes(_ref) {
  var history = _ref.history;
  var isNeedRender = useRef(true);

  var SwitchContent = function SwitchContent() {
    return React.createElement(Switch, null, routeList.map(function (route) {
      return React.createElement(Route, {
        key: "route-".concat(route.url),
        exact: route.exact,
        path: route.url,
        component: route.component
      });
    }));
  }; // method for once render Router
  // eslint-disable-next-line react-hooks/exhaustive-deps


  var RenderRouter = useCallback(function () {
    return React.createElement(Router, {
      history: history
    }, SwitchContent());
  }, [isNeedRender]);
  useEffect(function () {
    isNeedRender.current = false; // switch off after Router render
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(ThemeProvider, {
    theme: Theme
  }, isElectron ? React.createElement(HashRouter, null, SwitchContent()) : React.createElement(RenderRouter, null)), React.createElement(GlobalStyle, null));
}