function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useCallback, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from 'src/services';
import { MainRoute } from 'src/utils/const';
import Menu from 'components/gender-menu';

var GenderMenu = function GenderMenu(props) {
  var history = props.history,
      _props$match$params = props.match.params,
      params = _props$match$params === void 0 ? {} : _props$match$params;
  var ageId = Number(params.ageId);
  var genderId = Number(params.genderId);

  var _useContext = useContext(AppContext),
      bodyState = _useContext.bodyState,
      ageMenu = _useContext.ageMenu,
      extraMenu = _useContext.extraMenu,
      clearBodyState = _useContext.clearBodyState,
      clearExtraMenuList = _useContext.clearExtraMenuList,
      setState = _useContext.setState;

  var prepareExtraMenu = useCallback(function () {
    return extraMenu.map(function (item) {
      return _objectSpread({}, item, {
        caption: item.name
      });
    });
  }, [extraMenu]);

  if (!ageId || !genderId) {
    history.push(MainRoute.rawUrl());
  }

  useEffect(function () {
    setState({
      ageId: ageId,
      genderId: genderId
    });
    clearBodyState();
    clearExtraMenuList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0413\u043B\u0430\u0432\u043D\u043E\u0435 \u043C\u0435\u043D\u044E")), React.createElement(Menu, {
    ageId: Number(ageId),
    genderId: Number(genderId),
    bodyParts: bodyState.bodyParts,
    history: history,
    ageMenu: ageMenu,
    extraMenu: prepareExtraMenu(),
    setState: setState
  }));
};

export default GenderMenu;