import { css } from 'styled-components';
import theme from '../theme';
export var fontBase = function fontBase() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '16px';
  return css(["font-family:'Roboto';font-size:", ";font-weight:normal;font-style:normal;color:", ";"], size, theme.colors.darkIndigo900);
};
export var typeBase = function typeBase() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '16px';
  return css(["font-family:'Roboto',sans-serif;font-size:", ";font-weight:400;color:", ";"], size, theme.colors.darkIndigo900);
};
export var fontThin = function fontThin() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '16px';
  return css(["font-family:'Roboto';font-size:", ";font-weight:300;font-style:normal;color:", ";"], size, theme.colors.darkIndigo900);
};
export var fontTitle = function fontTitle() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '36px';
  return css(["font-family:'Roboto';font-size:", ";font-weight:600;font-style:normal;color:", ";"], size, theme.colors.white);
};
export var asLink = function asLink() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '16px';
  return css(["font-family:'Roboto';font-size:", ";font-weight:normal;font-style:normal;color:", ";&:hover{cursor:pointer;text-decoration:underline;}"], size, theme.colors.link);
};