import styled from 'styled-components';
import { Wrapper } from 'src/styles/utilities/helpers';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1vgqhy4-0"
})(["", ";padding-top:84px;"], Wrapper());
export var LeftSide = styled.div.withConfig({
  displayName: "styles__LeftSide",
  componentId: "sc-1vgqhy4-1"
})(["max-width:670px;"]);
export var BackButtonWrapper = styled.div.withConfig({
  displayName: "styles__BackButtonWrapper",
  componentId: "sc-1vgqhy4-2"
})(["position:fixed;display:flex;flex-direction:row;left:75px;bottom:75px;z-index:10;button{margin-right:30px;min-width:270px;text-align:center;justify-content:center;}"]);
export var Title = styled.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1vgqhy4-3"
})(["font-family:", ";font-size:50pt;font-weight:bold;color:", ";white-space:nowrap;margin:40px 0 0 70px;&:after{content:'';display:block;border-radius:50px;border:2px solid ", ";height:1px;width:100px;margin-top:13px;margin-left:5px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.fonts.kievit;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.blue500;
});
export var Description = styled.div.withConfig({
  displayName: "styles__Description",
  componentId: "sc-1vgqhy4-4"
})(["font-family:", ";font-size:22pt;font-weight:400;line-height:30pt;color:", ";margin-left:70px;margin-top:45px;"], function (_ref4) {
  var theme = _ref4.theme;
  return theme.fonts.kievit;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.white;
});
export var BodyMapContainer = styled.div.withConfig({
  displayName: "styles__BodyMapContainer",
  componentId: "sc-1vgqhy4-5"
})(["width:100%;height:100vh;margin-top:-84px;overflow:hidden;"]);