import React from 'react';
import { Button } from './styles';
export var BasicButton = function BasicButton(_ref) {
  var id = _ref.id,
      className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick,
      bigButton = _ref.bigButton;
  return React.createElement(Button, {
    "data-id": id,
    className: className,
    onClick: onClick,
    bigButton: bigButton
  }, children);
};