export default {
  colors: {
    main: '#0e0e12',
    white: '#FFFFFF',
    black: '#000000',
    mainDarkBlue: '#05204b',
    blue: '#31b2f6',
    opacity_mainDarkBlue: 'rgba(1, 20, 60, 0.9)',
    opacity_black: 'rgba(0, 0, 0, 0.1)',
    opacity_6_black: 'rgba(0, 0, 0, 0.6)',
    opacity_blue: 'rgba(49, 178, 246, 0.75)',
    opacity_blue_55: 'rgba(49, 178, 246, 0.55)',
    borderBlue: '#6182d5',
    modalBg: '#01133b',
    inputBg: '#03204d',
    keyboardButton: '#5a7293',
    scroll_blue: '#0b224a',
    blueGray50: '#eceff1',
    blueGray100: '#cfd8dc',
    blueGray200: '#b0bec5',
    blueGray300: '#90a4ae',
    blueGray400: '#78909c',
    blueGray500: '#607d8b',
    blueGray600: '#546e7a',
    blueGray700: '#455a64',
    blueGray800: '#37474f',
    blueGray900: '#263238',
    gray50: '#f9f9f9',
    gray100: '#ededed',
    gray200: '#e0e0e1',
    gray300: '#d2d2d3',
    gray400: '#c2c3c4',
    gray500: '#b1b3b4',
    gray600: '#9e9fa1',
    gray700: '#87898b',
    gray800: '#696c6f',
    gray900: '#3c3f43',
    blue50: '#e3f2fd',
    blue100: '#bbdefb',
    blue200: '#90caf9',
    blue300: '#64b5f6',
    blue400: '#42a5f5',
    blue500: '#2196f3',
    blue600: '#1e88e5',
    blue700: '#1976d2',
    blue800: '#1565c0',
    blue900: '#0d47a1',
    darkIndigo50: '#6198db',
    darkIndigo100: '#407cc5',
    darkIndigo200: '#2760a7',
    darkIndigo300: '#184d8d',
    darkIndigo400: '#113f77',
    darkIndigo500: '#0e3464',
    darkIndigo600: '#0c2e58',
    darkIndigo700: '#09284e',
    darkIndigo800: '#071f3d',
    //main background
    darkIndigo900: '#041427',
    redBase: '#d02138' //accent - brand

  },
  mediaQueries: {
    largeScreen: '1824px',
    laptop: '1224px',
    tablet: '768px',
    phone: '320px'
  },
  fonts: {
    size: '16px',
    main: 'Roboto',
    kievit: 'KievitPro'
  }
};