import React from 'react';
import { getImageLink } from 'src/utils';
import HouseIcon from 'src/assets/icons/house.png';
import MenuIcon from 'src/assets/icons/menu.svg';
import { BackButton, IconButton } from '../button';
import { Container, LeftSide, RightSide, OrganImage, DiseaseList, DiseaseListItem, DiseaseLink, BackButtonWrapper, Description } from './styles';

var IllnessesListView = function IllnessesListView(_ref) {
  var bodyPart = _ref.bodyPart,
      sicknessesList = _ref.sicknessesList,
      onSicknessClick = _ref.onSicknessClick,
      onBackClick = _ref.onBackClick,
      handleOpenMainPage = _ref.handleOpenMainPage,
      handleGenderMenuRouteOpen = _ref.handleGenderMenuRouteOpen;
  var haveSicknesses = sicknessesList.length > 0;
  return React.createElement(Container, null, React.createElement(LeftSide, null, bodyPart && React.createElement(OrganImage, {
    src: getImageLink(bodyPart.organ_image)
  })), React.createElement(RightSide, null, React.createElement(DiseaseList, null, haveSicknesses && sicknessesList.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }).map(function (sick) {
    return React.createElement(DiseaseListItem, {
      key: "sick-".concat(sick.id)
    }, React.createElement(DiseaseLink, {
      type: "button",
      "data-id": sick.id,
      onClick: onSicknessClick
    }, sick.name));
  })), React.createElement(Description, null, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0437\u0430\u0431\u043E\u043B\u0435\u0432\u0430\u043D\u0438\u0435, \u0447\u0442\u043E\u0431\u044B \u0443\u0437\u043D\u0430\u0442\u044C \u043E \u0441\u0438\u043C\u043F\u0442\u043E\u043C\u0430\u0445 \u0438 \u043A\u0430\u043A \u0438\u0445 \u0438\u0437\u0431\u0435\u0436\u0430\u0442\u044C")), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: onBackClick
  }, "\u041D\u0430\u0437\u0430\u0434"), React.createElement(IconButton, {
    iconLink: HouseIcon,
    onClick: handleOpenMainPage
  }, "\u0414\u043E\u043C\u043E\u0439"), React.createElement(IconButton, {
    iconLink: MenuIcon,
    onClick: handleGenderMenuRouteOpen
  }, "\u041C\u0435\u043D\u044E")));
};

export default IllnessesListView;