function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useContext, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppContext } from 'src/services';
import { ExtraListItem, Wrapper, SliderArrow } from './styles';
import { getExtraMenuRouteRawUrlList } from './consts';
var settings = {
  dots: false,
  infinite: true,
  focusOnSelect: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [{
    breakpoint: 1366,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 1324,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }]
};

var ExtraMenu = function ExtraMenu(_ref) {
  var items = _ref.items,
      _ref$initialSlide = _ref.initialSlide,
      initialSlide = _ref$initialSlide === void 0 ? null : _ref$initialSlide,
      _ref$additionalRouteD = _ref.additionalRouteData,
      additionalRouteData = _ref$additionalRouteD === void 0 ? {} : _ref$additionalRouteD,
      history = _ref.history;
  var slick = React.createRef();

  var _useContext = useContext(AppContext),
      _useContext$appContex = _useContext.appContext,
      ageId = _useContext$appContex.ageId,
      genderId = _useContext$appContex.genderId;

  var extraMenuRouteRawUrlList = getExtraMenuRouteRawUrlList();

  var handleExtraMenuChange = function handleExtraMenuChange(item) {
    var extraRoute = extraMenuRouteRawUrlList[item.type];
    history.push(extraRoute.url(extraRoute.prepareUrl(ageId, genderId, item.id, additionalRouteData)));
  };

  var handleClick = function handleClick(ev) {
    var index = items.map(function (item) {
      return item.type === ev.target.dataset.type ? item : {
        id: null
      };
    }).findIndex(function (item) {
      return item.id === Number(ev.target.dataset.id);
    });
    slick.current.slickGoTo(index);
    setTimeout(function () {
      handleExtraMenuChange(items[index]);
    }, 400);
  };

  var handleSlickNext = function handleSlickNext() {
    slick.current.slickNext();
    var nextSlide = initialSlide === items.length - 1 ? 0 : initialSlide + 1;
    var item = items[nextSlide];
    setTimeout(function () {
      handleExtraMenuChange(item);
    }, 400);
  };

  var handleSlickPrev = function handleSlickPrev() {
    slick.current.slickPrev();
    var prevSlide = !initialSlide ? items.length : initialSlide;
    var item = items[prevSlide - 1];
    setTimeout(function () {
      handleExtraMenuChange(item);
    }, 400);
  };

  var handleSwipe = function handleSwipe(direction) {
    switch (direction) {
      case 'right':
        handleSlickPrev();
        break;

      case 'left':
        handleSlickNext();
        break;

      default:
        break;
    }
  };

  useEffect(function () {
    initialSlide !== null && slick.current.slickGoTo(initialSlide);
  }, [initialSlide, slick]);
  return React.createElement(Wrapper, null, items.length > 0 && initialSlide !== null && React.createElement(Slider, _extends({
    ref: slick
  }, settings, {
    initialSlide: initialSlide,
    onSwipe: handleSwipe
  }), items.map(function (item) {
    return React.createElement(ExtraListItem, {
      key: "ExtraListItem-".concat(item.id),
      "data-id": item.id,
      "data-type": item.type,
      onMouseUp: handleClick,
      onTouchEnd: handleClick
    }, item.name);
  })), React.createElement(SliderArrow, {
    className: "slick-prev",
    onClick: handleSlickPrev,
    to: "prev"
  }), React.createElement(SliderArrow, {
    className: "slick-next",
    onClick: handleSlickNext,
    to: "next"
  }));
};

export default React.memo(ExtraMenu);