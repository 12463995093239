function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PreviewSections, getInitialPreviewBlocks } from 'components/preview-sections';
import { useFetchZogArticles } from 'hooks/zog-articles';
import { AppContext } from 'src/services';
import { MainRoute, GenderMenuRoute } from 'src/utils/const';
import { BackButton } from 'components/button';
import { ScrollBar } from 'components/scrollbar';
import Hint from 'components/hint';
import { BackButtonWrapper } from './styles';
export default function ZogCenterLanding(props) {
  var history = props.history,
      params = props.match.params;
  var genderId = Number(params.genderId);
  var ageId = Number(params.ageId);
  var zogItemId = Number(params.zogItemId);

  var _useContext = useContext(AppContext),
      setState = _useContext.setState,
      appContext = _useContext.appContext;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      centerArticle = _useState2[0],
      setCenterArticle = _useState2[1];

  var _useState3 = useState([]),
      _useState4 = _slicedToArray(_useState3, 2),
      filteredArticles = _useState4[0],
      setFilteredArticles = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      showEmailResult = _useState6[0],
      setShowEmailResult = _useState6[1];

  var _useState7 = useState(null),
      _useState8 = _slicedToArray(_useState7, 2),
      footer = _useState8[0],
      setFooter = _useState8[1];

  var _useFetchZogArticles = useFetchZogArticles(),
      zogArticles = _useFetchZogArticles.zogArticles,
      fetchZogMapedArticles = _useFetchZogArticles.fetchZogMapedArticles,
      zogMenuArticle = _useFetchZogArticles.zogMenuArticle,
      fetchZogMenuArticle = _useFetchZogArticles.fetchZogMenuArticle;

  var handleBackClick = function handleBackClick(event) {
    event.preventDefault();
    history.goBack();
  };

  var handleGenderMenuOpen = function handleGenderMenuOpen() {
    history.push(GenderMenuRoute.rawUrl({
      ageId: ageId,
      genderId: genderId
    }));
  };

  var handleHomePageOpen = function handleHomePageOpen() {
    history.push(MainRoute.rawUrl());
  };

  useEffect(function () {
    if (ageId && genderId) {
      if (appContext.ageId !== ageId && appContext.genderId !== genderId) {
        setState({
          ageId: ageId,
          genderId: genderId
        });
      }
    }
  }, [ageId, appContext.ageId, appContext.genderId, genderId, setState]);
  useEffect(function () {
    if (zogArticles.length > 0 && centerArticle === null) {
      var articleCenter = zogArticles.find(function (item) {
        return item.center;
      });
      setCenterArticle(articleCenter);
    }
  }, [zogArticles, centerArticle]);
  useEffect(function () {
    if (zogArticles.length > 0 && footer === null) {
      var footerArticle = zogArticles.find(function (item) {
        return item.footer;
      });
      setFooter(footerArticle);
    }
  }, [footer, zogArticles]);
  useEffect(function () {
    var handleOrder = function handleOrder(a, b) {
      return a.posId - b.posId;
    };

    if (zogArticles.length > 0 && !filteredArticles.length) {
      var _articles = zogArticles.filter(function (item) {
        return !item.center && !item.footer;
      }).sort(handleOrder);

      setFilteredArticles(_articles);
    }
  }, [zogArticles, filteredArticles.length]);
  useEffect(function () {
    fetchZogMapedArticles(ageId);

    if (zogItemId) {
      fetchZogMenuArticle(zogItemId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  var articles = useMemo(function () {
    var result = [];

    if (filteredArticles.length > 0) {
      result = !showEmailResult ? filteredArticles : filteredArticles.slice(-1);
    }

    return result;
  }, [filteredArticles, showEmailResult]);
  var checkCanScrollTo = useCallback(function (zogArticleId) {
    return zogMenuArticle.length > 0 && zogMenuArticle[0].id === zogArticleId;
  }, [zogMenuArticle]);
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, "\u0417\u041E\u0416. \u0426\u0435\u043D\u0442\u0440\u0430\u043B\u044C\u043D\u044B\u0439 \u043B\u0435\u043D\u0434\u0438\u043D\u0433")), React.createElement(ScrollBar, {
    landingId: 0,
    noScrollTop: true
  }, centerArticle && !showEmailResult && React.createElement(PreviewSections, {
    blocks: getInitialPreviewBlocks({
      value: centerArticle.value
    }),
    handleGenderMenuOpen: handleGenderMenuOpen,
    handleHomePageOpen: handleHomePageOpen,
    showEmailResult: showEmailResult,
    setShowEmailResult: setShowEmailResult,
    noShift: true,
    noFooter: true,
    setState: setState
  }), !showEmailResult && articles.map(function (item, index) {
    return React.createElement(PreviewSections, {
      key: "preview-".concat(item.id),
      scrollTo: checkCanScrollTo(item.id),
      blocks: getInitialPreviewBlocks({
        value: item.value
      }),
      handleGenderMenuOpen: handleGenderMenuOpen,
      handleHomePageOpen: handleHomePageOpen,
      showEmailResult: showEmailResult,
      setShowEmailResult: setShowEmailResult,
      noShift: true,
      noFooter: Boolean(footer) || index !== filteredArticles.length - 1,
      setState: setState
    });
  }), footer && React.createElement(PreviewSections, {
    blocks: getInitialPreviewBlocks({
      value: footer.value
    }),
    handleGenderMenuOpen: handleGenderMenuOpen,
    handleHomePageOpen: handleHomePageOpen,
    showEmailResult: showEmailResult,
    setShowEmailResult: setShowEmailResult,
    currentLandingUrl: history.location.pathname,
    noShift: true,
    setState: setState
  })), !showEmailResult && React.createElement(React.Fragment, null, React.createElement(Hint, null), React.createElement(BackButtonWrapper, null, React.createElement(BackButton, {
    onClick: handleBackClick
  }, "\u041D\u0430\u0437\u0430\u0434"))));
}