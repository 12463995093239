import React, { useCallback, Fragment, useContext, useEffect } from 'react';
import { Environment } from 'src/services';
import { AppContext } from 'src/services/context.service';
import { Container, Wrapper, AgeItemWrapper, AgeItem, Image, CenterBlock, DatePickerIcon, CenterBlockTitle, Text } from './styles';
var baseImgUrl = Environment.BaseImageURL;

var AgeSelector = function AgeSelector(_ref) {
  var ageMenu = _ref.ageMenu,
      selectAge = _ref.selectAge;

  var _useContext = useContext(AppContext),
      clearExtraMenu = _useContext.clearExtraMenu;

  var handleSelectAge = useCallback(function (ev) {
    selectAge({
      ageId: Number(ev.currentTarget.dataset.id)
    });
  }, [selectAge]);

  var parseText = function parseText(text) {
    return text.split(' ').map(function (item) {
      var isNumber = !isNaN(item);
      var result = "".concat(item, ' ');

      if (isNumber) {
        result = React.createElement("span", {
          key: "parsed-text-number-".concat(item)
        }, " ", item, " ");
      } else if (item.includes('+')) {
        result = React.createElement(Fragment, {
          key: "fragment-".concat(item)
        }, React.createElement("span", {
          key: "parsed-text-".concat(item)
        }, " ", item.slice(0, 2), " "), '+ ');
      }

      return result;
    });
  };

  useEffect(function () {
    clearExtraMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return React.createElement(Wrapper, null, React.createElement(Container, null, React.createElement(AgeItemWrapper, null, ageMenu.map(function (age) {
    return React.createElement(AgeItem, {
      key: "age-item-image-".concat(age.id, "-").concat(age.pos),
      "data-id": age.id,
      onClick: handleSelectAge
    }, React.createElement(Image, {
      src: "".concat(baseImgUrl).concat(age.icon),
      zoom: age.zoom,
      posX: age.posX,
      posY: age.posY
    }));
  })), React.createElement(CenterBlock, null, React.createElement(DatePickerIcon, null), React.createElement(CenterBlockTitle, null, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E \u0432\u043E\u0437\u0440\u0430\u0441\u0442\u0430"))), React.createElement("div", null, ageMenu.map(function (age) {
    return React.createElement(Text, {
      key: "age-item-text-".concat(age.id),
      "data-id": age.id,
      onClick: handleSelectAge
    }, parseText(age.name));
  })));
};

export default AgeSelector;