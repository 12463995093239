import React from 'react';
import MailIcon from 'src/assets/icons/mail-white.png';
import { Button, LinkWrapper, Image } from './styles';
export var EmailButton = function EmailButton(_ref) {
  var className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick,
      disabled = _ref.disabled,
      _ref$iconLink = _ref.iconLink,
      iconLink = _ref$iconLink === void 0 ? MailIcon : _ref$iconLink;
  return React.createElement(Button, {
    className: className,
    onClick: onClick,
    disabled: disabled
  }, iconLink && React.createElement(LinkWrapper, null, React.createElement(Image, {
    src: iconLink
  })), children);
};