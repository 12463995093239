import React from 'react';
import { Wrapper, Title, Image } from './styles';

var GenderImage = function GenderImage(_ref) {
  var genderId = _ref.genderId,
      _ref$title = _ref.title,
      title = _ref$title === void 0 ? '' : _ref$title,
      imgSrc = _ref.imgSrc;
  return React.createElement(Wrapper, null, React.createElement(Image, {
    imgSrc: imgSrc
  }), React.createElement(Title, {
    genderId: genderId
  }, title));
};

export default GenderImage;