function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { ExtraMenuTypes } from 'components/extra-menu/interface';
import { AnatomyRoute, ChildrenGrowingRoute, IllnessRoute, IlnessesListRoute, LandingRoute, TestRoute, ZogRoute } from 'src/utils/const';

var extraMenuMap = function extraMenuMap(ageId, genderId, itemKey, itemId, additionalData) {
  var result = {
    ageId: ageId,
    genderId: genderId
  };

  if (itemKey) {
    result[itemKey] = itemId;
  }

  if (additionalData && Object.keys(additionalData).length > 0) {
    result = _objectSpread({}, result, {}, additionalData);
  }

  return result;
};

export var getExtraMenuRouteRawUrlList = function getExtraMenuRouteRawUrlList() {
  var _ref;

  return _ref = {}, _defineProperty(_ref, ExtraMenuTypes.BODY_PARTS, {
    url: IlnessesListRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId, itemId, additionalData) {
      return extraMenuMap(ageId, genderId, 'bodyPartId', itemId, additionalData);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.EXTRA_MENU, {
    url: LandingRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId, itemId, additionalData) {
      return extraMenuMap(ageId, genderId, 'landingId', itemId, additionalData);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.ZOG, {
    url: ZogRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId) {
      return extraMenuMap(ageId, genderId);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.LANDINGS, {
    url: IllnessRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId, itemId, additionalData) {
      return extraMenuMap(ageId, genderId, 'sicknessId', itemId, additionalData);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.ALL_SICKNESS, {
    url: AnatomyRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId) {
      return extraMenuMap(ageId, genderId);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.TEST, {
    url: TestRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId) {
      return extraMenuMap(ageId, genderId);
    }
  }), _defineProperty(_ref, ExtraMenuTypes.CHILDREN_GROWING, {
    url: ChildrenGrowingRoute.rawUrl,
    prepareUrl: function prepareUrl(ageId, genderId) {
      return extraMenuMap(ageId, genderId);
    }
  }), _ref;
};