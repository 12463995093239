function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// import { StorageService } from './storage.service';
// import { DateTimeService } from './dateTime.service';
function saveToken(userToken, expired) {
  try {
    localStorage.setItem('userToken', userToken);
    localStorage.setItem('tokenExpired', String(expired));
  } catch (error) {
    throw new Error(error);
  }
}

function getToken() {
  return localStorage.getItem('userToken');
}

function isAuth() {
  var token = localStorage.getItem('userToken');
  var expired = localStorage.getItem('tokenExpired');
  var now = Date.now();
  var tokenIsValid = token && token.trim().length > 0 && now < Number(expired); // const tokenExpired = new Date(Number(expired)).toLocaleString('ru');
  // console.log(tokenExpired);

  return Boolean(tokenIsValid);
}

export var AuthService = function AuthService() {
  _classCallCheck(this, AuthService);
};

_defineProperty(AuthService, "saveToken", saveToken);

_defineProperty(AuthService, "getToken", getToken);

_defineProperty(AuthService, "isAuth", isAuth);