import styled from 'styled-components';
export var BodyMapWrapper = styled.div.withConfig({
  displayName: "styles__BodyMapWrapper",
  componentId: "sc-1y5sfqt-0"
})(["display:block;justify-content:center;width:100%;min-height:100vh;height:100%;left:-15%;overflow:hidden;position:relative;&::-webkit-scrollbar{width:10px;height:10px;}&::-webkit-scrollbar-thumb{background:", ";}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.gray700;
});
export var ImageWrapper = styled.div.withConfig({
  displayName: "styles__ImageWrapper",
  componentId: "sc-1y5sfqt-1"
})(["display:flex;flex-flow:row nowrap;justify-content:center;left:50%;bottom:0;position:absolute;width:600px;height:900px;margin:auto;color:aliceblue;transform:translateX(-50%);"]);
export var Avatar = styled.img.withConfig({
  displayName: "styles__Avatar",
  componentId: "sc-1y5sfqt-2"
})(["width:auto;height:900px;color:", ";"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
});
export var OrganImage = styled.img.withConfig({
  displayName: "styles__OrganImage",
  componentId: "sc-1y5sfqt-3"
})(["position:absolute;height:auto;display:block;z-index:-1;"]);